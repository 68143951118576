import React, { useEffect, useContext, useState, useRef } from "react";
import { userContext } from "../../../../context/userContext";
import { environmentVariables } from "../../../../config/env.config";
import { useParams, useLocation } from "react-router-dom";
import whitecircle from "../../../../Images/white-circle.png";
import { getTimeZone } from "../../getTimeZone";
import { businessObjContext } from "../../../../context/businessObjContext";
import SubContactInfo from "./SubContactInfo";
import SubGoogleReview from "./SubGoogleReview";
import SubSocialMedia from "./SubSocialMedia";
import SubService from "./SubService";
import SubTestimonial from "./SubTestimonial";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
// import io, { socketIOClient } from "socket.io-client";
import SubBusinessHoursData from "./SubBusinessHoursData";
import axios from "axios";
import QRCode from "qrcode.react";
// import "./TemplatComman.css";
// import "./Template11.css";
import "./Theme1.css";
import { ToastContainer, toast } from "react-toastify";
import CircularLoader from "../../../Components/CircularLoader/CircularLoader";
import CalanderIconn from "../../../../Images/DarkcalendarIcondays.png";
import banner from "../../../../Images/banner1template.jpg";
import logo from "../../../../Images/logo1.jpg";
import GalleryCarousel from "./GalleryCarousel";
import QrCodeGenerator from "../theme6/QRCode";
import whiteCircle from "../../../../../src/Images/white-circle.png";
import { getAvailableSlots, getServicesArray } from "../../../../utils/utility";
import moment from "moment";
import Star1 from "../../../../Images/Img1FullStar.png";

// const socket = io.connect(`${environmentVariables?.apiUrl}`);
const Theme1Ext = (props) => {
  const rs = props.rs;
  const [businessLink, setBusinessLink] = useState("");
  const [storedData, setStoredData] = useState(
    localStorage.getItem("businessObj")
  );
  const baseUrl = environmentVariables?.apiUrl;
  const { userData } = useContext(userContext);
  let { param } = useParams();
  const [pparam, setPparam] = useState([]);
  const calendarInputRef = useRef(null);
  const [isSubmitContact, setIsSubmitContact] = useState(false);
  const [isSubmitAppointment, setIsSubmitAppointment] = useState(false);
  const [showTimeSlots, setShowTimeSlots] = useState(false);
  const [theme, setTheme] = useState();
  const searchParams = new URLSearchParams(window.location.search);
  const appParams=searchParams.get('isApp');
  const paramValue = searchParams.get("param");
  const location = useLocation();
  // const [rs, setRs] = useState({});
  const [appointmentdata, setAppointmentdata] = useState();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [loadingAppointment, setLoadingAppointment] = useState(false);
  const [galleryObject, setGalleryObject] = useState(null);
  const [textareaValue, setTextareaValue] = useState("");
  const [backColor, setBackColor] = useState();
  const createUserRef = useRef(null);

  const currentDate = new Date();
  const formattedDate = currentDate.toISOString();
  const { businessObjData } = useContext(businessObjContext);
  const [order, setOrder] = useState(null);

  const [appointmentName, setAppointmentName] = useState("");
  const [appointmentEmail, setAppointmentEmail] = useState("");
  const [appointmentContactNumber, setAppointmentContactNumber] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  // console.log(selectedTime, "selectedTime");
  const [minDate, setMinDate] = useState("");

  const [errorMsgName, setErrorMsgName] = useState("");
  const [errorMsgEmail, setErrorMsgEmail] = useState("");
  const [errorMsgNo, setErrorMsgNo] = useState("");
  const [errorMsgText, setErrorMsgText] = useState("");
  const [countrya, setCountry] = useState("");

  const [isButtonClicked, setIsButtonClicked] = useState(false);
// console.log(rs?.is_enabledGooglereview,"propspropsprops")
  useEffect(() => {
    const scrollToSection = () => {
      const element = document.getElementById("BookAppointmentAccess");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      } else {
        setTimeout(scrollToSection, 100);
      }
    };
    if (window.location.hash === "#BookAppointmentAccess") {
      scrollToSection();
    }
  }, []);

  
  useEffect(() => {
    // Set the minimum date to the current date
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setMinDate(formattedDate);
  }, []);
  // useEffect(() => {
  //   socket.emit("join_room", rs?.created_by);
  // }, [socket]);
  const getAppointmentData = async (date) => {
    setLoadingAppointment(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/appointment/fetchAppointmentSlots?userId=${userData?.id}&businessId=${rs?.id}&date="${date}"`,
      headers: {
        _token: userData?.token,
      },
    };

    try {
      const response = await axios.request(config);
      console.log(
        response?.data?.response[0]?.slot,
        "responseofgetappointmentdata"
      );
      setLoadingAppointment(false);
      let slotData = response?.data?.response[0]?.slot;
      let filteredSlots = await getAvailableSlots(slotData, date);
      return filteredSlots;
    } catch (error) {
      console.log(error);
      setLoadingAppointment(false);

      return error?.message;
    }
  };

  const handleChangeAppointmentDate = async (dateVal) => {
    let newDate = moment(dateVal).format("YYYY-MM-DD");
    setShowTimeSlots(true);
    setSelectedDate(dateVal);
    // const dateObject = new Date(dateVal);

    // const dayIndex = dateObject.getDay();

    // const daysOfWeek = [
    //   "Sunday",
    //   "Monday",
    //   "Tuesday",
    //   "Wednesday",
    //   "Thursday",
    //   "Friday",
    //   "Saturday",
    // ];
    // console.log(rs?.appointmentObj?.content, "appointmentinsidetheme1date");
    try {
      // const parsedContent = JSON.parse(JSON.parse(rs?.appointmentObj?.content));
      // setAppointmentdata(
      //   parsedContent[`${daysOfWeek[dayIndex].toLowerCase()}`]
      // );
      const appointments = await getAppointmentData(newDate);
      setAppointmentdata(appointments);
    } catch (err) {
      console.log(err);
    }
  };
  const handleButtonClick = () => {
    setIsButtonClicked(true);
  };

  let ff = rs?.contactInfo?.content;

  if (ff?.length) {
    ff = JSON?.parse(ff);
  }
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }
  const getThemeNumber = (themeNum) => {
    return themeNum.split("-").pop();
  };
  const getBackColor = (backColor) => {
    const color = backColor.split("-")[0];
    if (color == "color1") {
      return "#F9D254";
    } else if (color == "color2") {
      return "#8ECAE6";
    } else if (color == "color3") {
      return "#F9D254";
    } else if (color == "color4") {
      return "#FDF0D5";
    } else {
      return "#F9D254";
    }
  };

  useEffect(() => {
    const path = location?.pathname;
    const pathParts = path.split("/");
    setPparam(pathParts);
  }, [location]);

  const getImage = (image) => {
    if (image.includes("base64")) {
      return image;
    } else {
      return `${environmentVariables?.apiUrl}uploads/${image}`;
    }
  };

  const openCalendar = () => {
   
    if (calendarInputRef.current) {
      calendarInputRef.current.showPicker();
     
    }
  };

  function isValidPhoneNumber(phoneNumber) {
    const phonePattern = /^\d{10,12}$/; // Assuming a 10-digit phone number

    return phonePattern.test(phoneNumber);
  }

  const isValidName = (name) => {
    // This regex pattern allows letters (both uppercase and lowercase), hyphens, and spaces.
    const nameRegex = /^[A-Za-z -]+$/;
    return nameRegex.test(name);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailRegex.test(email);
  };

  // const getPlanName = () => {
  //   const path = location.pathname;
  //   const pp = path.split("/");
  //   axios
  //     .get(`${baseUrl}api/v1/business/fetchBusinessBySlug`, {
  //       params: { slug: props?.Slug },
  //       headers: {
  //         //   _token: userData?.token,
  //       },
  //       // withCredentials: true,
  //     })
  //     .then((res) => {
  //       console.log(res?.data?.data?.theme_color, "ERERER");
  //       setRs(res?.data?.data);
  //       let appointmentData2 = [];
  //       if (
  //         res?.data?.data &&
  //         res?.data?.data?.appointmentObj &&
  //         res?.data?.data?.appointmentObj?.content
  //       ) {
  //         appointmentData2 = JSON.parse(
  //           res?.data?.data?.appointmentObj?.content
  //         );

  //         if (Array.isArray(appointmentData2)) {
  //           setAppointmentdata(appointmentData2);
  //         }
  //       }

  //       console.log(appointmentData2, "appointmentdata 22324 rs rs");
  //       setGalleryObject(JSON.parse(res?.data?.data?.gallaryObj?.content));
  //       setBackColor(getBackColor(res?.data?.data?.theme_color));
  //       console.log(
  //         getTemplateId(res?.data?.data?.theme_color.split("-").pop()),
  //         "template id number"
  //       );
  //       setTheme(getTemplateId(res?.data?.data?.theme_color.split("-").pop()));
  //     })
  //     .catch((err) => {
  //       console.log({ msg: "err", err: err });
  //     });
  // };

  // console.log("Paddddddrts:", pparam);

  useEffect(() => {
    if (storedData != "undefined") {
      const businessData = JSON.parse(storedData);
      const slugb = businessData?.slug;
      const originUrl = window.location.origin;
      setBusinessLink(`${originUrl}/${slugb}`);
    }
  }, [storedData]);

  // useEffect(() => {
  //   getPlanName();
  // }, []);

  const changeTimeFormat = (time24) => {
    // Split the time string into hours and minutes
    const [hours, minutes] = time24.split(":").map(Number);

    // Create a Date object with today's date
    const date = new Date();

    // Set the hours and minutes to the Date object
    date.setHours(hours);
    date.setMinutes(minutes);

    // Format the time in AM/PM format
    const time12 = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    return time12;
  };
  let contactInfo = props?.rs?.contactInfo?.content;
  if(contactInfo!=undefined&&contactInfo?.length>0){

    if (contactInfo?.length) {
        contactInfo = JSON.parse(contactInfo);
      }
      if (contactInfo?.length) {
          contactInfo = JSON.parse(contactInfo);
        }
      }
  

 

  let socialLinks = props?.rs?.SocialMediaObj?.content;
  let SocialLinkData;
  if
  (socialLinks&&socialLinks?.length

  ){

    SocialLinkData = JSON.parse(socialLinks);
  }
  let SocialLinkData1;
  if(
    SocialLinkData&& SocialLinkData.length  ){
   
 SocialLinkData1 = JSON.parse(SocialLinkData);
  }


  let socialLinksObject = {};
  SocialLinkData1&&SocialLinkData1?.map(element => {
      socialLinksObject[element?.nameOfSocial] = element?.link;
  });


  // console.log(SocialLinkData1)

const VCardContent = `BEGIN:VCARD
VERSION:3.0
N:${props?.rs?.sub_title}
FN:${props?.rs?.sub_title}
ORG:${props?.rs?.title}
TITLE:${props?.rs?.designation}
TEL;TYPE=CELL:${contactInfo?.contactInfoPhone}
EMAIL;TYPE=INTERNET:${contactInfo?.contactInfoEmail}
ADR;TYPE=HOME:;${contactInfo?.contactInfoAddress}
X-ABADR:country
X-ABLabel:Title
X-ABLabel:eBusiness Card
NOTE;CHARSET=UTF-8:${props?.rs?.description?.replace(/\s+/g, ' ').trim()}
URL:https://app.businessbay.io/${props?.rs?.slug}
END:VCARD`;

// console.log(props?.rs?.SocialMediaObj?.content,"contactInfocontactInfo")

  const downloadVCard = () => {
    const blob = new Blob([VCardContent], { type: "text/vcard" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "contact.vcf"; // Set the desired filename here

    // Create a click event
    const clickEvent = new MouseEvent("click", {
      bubbles: true,
      cancelable: false,
      view: window,
    });

    // Dispatch the click event
    a.dispatchEvent(clickEvent);

    // Revoke the object URL to free up resources
    window.URL.revokeObjectURL(url);
  };

  // const businessHoursData = JSON.parse(rs.businessHourObj.content);
  const businessHoursData = rs.businessHourObj
    ? JSON.parse(rs.businessHourObj.content)
    : null;

  const changeTimeFormatt = (time24) => {
    // Split the time string into hours and minutes
    const [hours, minutes] = time24.split(":").map(Number);

    // Create a Date object with today's date
    const date = new Date();

    // Set the hours and minutes to the Date object
    date.setHours(hours);
    date.setMinutes(minutes);

    // Format the time in AM/PM format
    const time12 = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    return time12;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMsgName("");
    setErrorMsgEmail("");
    setErrorMsgNo("");
    setErrorMsgText("");

    if (!name) {
      setErrorMsgName("Name is mandatory");
      return;
    } else if (!isValidName(name)) {
      setErrorMsgName("Only alphabets required");
      return;
    }

    if (!email) {
      setErrorMsgEmail("Email is mandatory");
      return;
    } else if (!isValidEmail(email)) {
      setErrorMsgEmail("Invalid email format");
      return;
    }

    if (!isValidPhoneNumber(contactNumber)) {
      setErrorMsgNo(
        "Invalid Mobile number."
      );
      return;
    }

    if (!textareaValue) {
      setErrorMsgText("Message is mandatory");
      return;
    }

    setIsSubmitContact(true);

    try {
      const response = await axios.post(
        `${baseUrl}api/v1/contact/MakeContact`,
        {
          business_id: props.id,
          name,
          email,
          phone: contactNumber,
          message: textareaValue,
          createdBy: props?.rs?.created_by,
        },
        {
          headers: {
            _token: userData?.token,
          },
        }
      );
      // socket.emit("contact", rs?.created_by);
      toast.success("Thank you for contacting us", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsSubmitContact(false);
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsSubmitContact(false);
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getBlockOrder();
    if (rs?.newGalleryImages) {
      setGalleryObject(rs?.newGalleryImages);
    }
    if (rs?.appointmentObj && rs?.appointmentObj?.content) {
      try {
        // const parsedContent = JSON.parse(
        //   JSON.parse(rs?.appointmentObj?.content)
        // );
        // console.log(parsedContent, "...............//////////////");
        // if (Array.isArray(parsedContent) && parsedContent.length !== 0) {
        // setAppointmentdata(parsedContent);
        // }
        // if (JSON.parse(rs?.appointmentObj?.content)?.length != 0) {
        //   if (JSON.parse(JSON.parse(rs?.appointmentObj?.content))) {
        //     setAppointmentdata(
        //       JSON.parse(JSON.parse(rs?.appointmentObj?.content))
        //     );
        //   }
        // }
      } catch (err) {
        console.log(err);
      }
    }
  }, [rs]);
  const getTemplateId = (theme) => {
    if (theme == "theme1") {
      return "template3";
    } else if (theme == "theme2") {
      return "template4";
    } else if (theme == "theme3") {
      return "template5";
    } else if (theme == "theme4") {
      return "template6";
    } else if (theme == "theme5") {
      return "template7";
    } else if (theme == "theme6") {
      return "template8";
    }
  };
  const getBlockOrder = async () => {
    // const handleGetBlock = async () => {
    try {
      let id = props?.id;

      if (id) {
        let { data: get } = await axios.get(
          `${environmentVariables?.apiUrl}api/v1/business/FetchBlock?business_id=${id}`,
          {
            headers: {
              _token: userData?.token,
            },
            // withCredentials: true,
          }
        );
        // console.log(get?.data?.order, "order data template2");
        setOrder(get?.data?.order);
      }

      // console.log(get.data.order,"Getetgwef")
    } catch (err) {
      console.log(err);
    }
    // };
  };

  const getBlocksOrder = () => {
    let BlocksArray = [];
    for (const key in order) {
      BlocksArray[order[key]] = getJSXElement(key);
    }
    return BlocksArray;
  };

  useEffect(() => {
    if (props.rs?.contactInfo?.content) {
        const contactInfo = getServicesArray(props?.rs?.contactInfo?.content); 
        let contactInfoData = contactInfo.length == 0?JSON.parse(props?.rs?.contactInfo?.content):contactInfo;
              
        let phoneNumber = contactInfoData?.contactInfoPhone;
        phoneNumber = phoneNumber?.replace(/[^\d+]/g, '');

        let countryCode = phoneNumber?.match(/^\+(\d{1,3})/)?.[1];

        if (countryCode?.length < 3) {
            if (phoneNumber.startsWith("+971")) {
                countryCode = "971";
            } else if (phoneNumber.startsWith("+91")) {
                countryCode = "91";
            } else if (phoneNumber.startsWith("+1")) {
                countryCode = "1";
            } else {
                countryCode = "default"; 
            }
        }

        switch (countryCode) {
            case '91':
              setCountry('India');
                break;
            case '1':
              setCountry('USA');
                break;
            case '971':
              setCountry('United Arab Emirates');
                break;
            default:
              setCountry('India'); 
                break;
        }
    }
}, [props?.rs?.contactInfo]);

  const getJSXElement = (elemName) => {
    // console.log(elemName, "elemName");
    if (elemName == "appointment") {
      if (rs?.appointmentObj?.is_enabled === 1) {
        return (
          <>
            <article class="makeappitmneets sec" id="BookAppointmentAccess">
              <h3 class="sectitle">Make Appointment</h3>

              <div className="contact-form">
                {countrya && (
                  <h5 style={{ marginBottom: "18px" }}>{`${
                    countrya
                  } (GMT${getTimeZone(countrya)})`}</h5>
                )}
                <div class="col-12 mb-3" style={{position:"relative"}}>
                  {/* <input
                    type="date"
                    ref={calendarInputRef}
                    class="form-control clear_string clor-blk"
                    placeholder="Date"
                    name="currentDate"
                    value={selectedDate}
                    min={minDate}
                    onChange={(e) =>
                      handleChangeAppointmentDate(e.target.value)
                    }
                  />
               <div className="calanderIcon firefox-hide" onClick={openCalendar}><img src={CalanderIconn} alt="Calendar Icon" /></div> */}
                <DatePicker
                    selected={selectedDate}
                    ref={calendarInputRef}
                    minDate={new Date()}
                    
                    placeholderText="Date"
                    onChange={
                      (date) =>
                        handleChangeAppointmentDate(date)
                    }
                    className="form-control"
                    popperPlacement="bottom-end"
                    onKeyDown={(e) => {
                      e.preventDefault();
                   }}
                  />
                  {/* <div className="calanderIcon firefox-hide" onClick={openCalendar}><img src={CalanderIconn} alt="Calendar Icon" /></div> */}
                  <div className="calanderIcon firefox-hide" onClick={() => calendarInputRef.current.setOpen(true)}><img src={CalanderIconn} alt="Calendar Icon" /></div>
              </div>
                {showTimeSlots && (
                  <>
                    {loadingAppointment ? (
                      <div className="skeleton-blog">
                        <div className="skeleton-footer"></div>
                      </div>
                    ) : (
                      <div class="col-12 mb-3">
                        <select
                          disabled={appointmentdata?.length == 0}
                          class="form-select"
                          aria-label="Default select example"
                          onChange={(e) => setSelectedTime(e.target.value)}
                        >
                          <option value="Select time">
                            {appointmentdata?.length == 0
                              ? "No Slots Available for Meeting"
                              : "Select time"}
                          </option>
                          {appointmentdata &&
                            appointmentdata?.map((val) => (
                              <option
                                disabled={val.status == "confirm"}
                                key={val?.startDate}
                                value={`${val?.startDate} - ${val?.endDate}`}
                              >
                                {`${val?.startDate} - ${val?.endDate}`}
                              </option>
                            ))}
                        </select>
                      </div>
                    )}
                  </>
                )}
                <div class="col-12 mb-3">
                  <button
                    className="btn btnmakeappitmnets"
                    data-bs-toggle="modal"
                    data-bs-target="#appotimnets"
                    disabled={!selectedDate || !selectedTime}
                  >
                    Make An Appointment
                  </button>
                </div>
              </div>
            </article>
          </>
        );
      }
    } else if (elemName == "contact_form") {
      if (rs?.is_contact_form_enabled) {
        return (
          <article className="conatctform sec">
            <h3 class="sectitle">Contact </h3>
            <div class="contact-form">
              <form onSubmit={handleSubmit}>
                <div class="col-12 mb-3">
                  <label class="form-label">Full Name</label>
                  <input
                    type="text"
                    class="form-control clear_string"
                    placeholder="Full Name*"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required=""
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",

                      bottom: "70px",
                    }}
                  >
                    {errorMsgName}
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <label class="form-label">Email address</label>
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Email*"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required=""
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",

                      bottom: "70px",
                    }}
                  >
                    {errorMsgEmail}
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <label class="form-label">Mobile No.</label>
                  <input
                    type="text"
                    class="form-control clear_string"
                    maxlength="14"
                    minlength="7"
                    placeholder="Mobile Number*"
                    name="mobile_number"
                    value={contactNumber}
                    // onChange={(e) => setContactNumber(e.target.value)}
                    onChange={(e) => {
                      const onlyNumbers = e.target.value.replace(/[^0-9]/g, '');
                      setContactNumber(onlyNumbers);
                    }}
                    required=""
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",

                      bottom: "70px",
                    }}
                  >
                    {errorMsgNo}
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <label class="form-label">Message</label>
                  <textarea
                    class="form-control clear_string"
                    rows="3"
                    placeholder="Message*"
                    required=""
                    name="message"
                    value={textareaValue}
                    onChange={(e) => setTextareaValue(e.target.value)}
                  ></textarea>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",

                      bottom: "70px",
                    }}
                  >
                    {errorMsgText}
                  </div>
                </div>

                <div class="col-12 mb-3">
                  {isSubmitContact ? (
                    <div>
                      <CircularLoader size={20} />
                    </div>
                  ) : (
                    <button type="submit" class="btn btn-submitform">
                      Submit
                    </button>
                  )}
                </div>
              </form>
            </div>
            <ToastContainer />
          </article>
        );
      }
    } else if (elemName === "business_hour") {
      if (rs?.businessHourObj?.is_enabled === 1) {
        //  console.log(businessHoursData, "11111")
        return <SubBusinessHoursData rs={rs} />;
      }
    } else if (elemName === "contact_info") {
      if (rs?.contactInfo?.is_enabled === 1) {
        return <SubContactInfo rs={rs} />;
      }
    } else if (elemName == "custom_html") {
      return <></>;
    } else if (elemName == "description") {
      return (
        <>
          <article className="abouttemplate sec">
            <h3 class="sectitle">About me</h3>
            <p 
             dangerouslySetInnerHTML={{ 
              __html: rs?.description?.replace(/(?:\r\n|\r|\n)/g, '<br>') 
            }}>
              
            </p>
          </article>
        </>
      );
    } else if (elemName == "gallery") {
      if (rs?.gallaryObj?.is_enabled === 1) {
        return (
          galleryObject && (
            <>
              {/* <article class="businesshour sec">
            <h3 class="sectitle">Gallery </h3>
            <div className="servesboxhere">
              <div className="imgboxhere">
                <img
                  src={
                    galleryObject?.gallaryStr &&
                    `${environmentVariables?.apiUrl}uploads/${galleryObject?.gallaryStr}`
                  }
                  className="mx-auto d-block img-fluid"
                />
              </div>
              {galleryObject?.gallary_video && (
                <div className="imgboxhere mt-3">
                  <video className="mx-auto d-block img-fluid" controls>
                    <source
                      width={100}
                      src={
                        galleryObject?.gallary_video &&
                        `${environmentVariables?.apiUrl}uploads/${galleryObject?.gallary_video}`
                      }
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              )}
            </div>
          </article> */}
              <article class="gallery sec sec-common">
                <h3 class="sectitle">Portfolio </h3>
                {/* <ul class="gallerylist">
                  {galleryObject?.gallaryArray &&
                    galleryObject?.gallaryArray.map((val) => (
                      <li>
                        <img
                          src={
                            `${environmentVariables?.apiUrl}uploads/${val}`
                            // props?.gallaryObject?.gallaryStr &&
                            // `${environmentVariables?.apiUrl}uploads/${props?.gallaryObject?.gallaryStr}`
                          }
                          className="mx-auto d-block img-fluid"
                        />
                      </li>
                    ))}

                  {galleryObject?.gallary_video && (
                    <li>
                      <video className="mx-auto d-block img-fluid" controls>
                        <source
                          src={
                            galleryObject?.gallary_video &&
                            `${environmentVariables?.apiUrl}uploads/${galleryObject?.gallary_video}`
                          }
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </li>
                  )}
                </ul> */}
                <div>
                  <GalleryCarousel slides={galleryObject} />
                </div>
              </article>
            </>
          )
        );
      }
    } else if (elemName == "more") {
      return <></>;
    } else if (elemName === "service") {
      if (rs?.serviceObj?.is_enabled === 1) {
        return <SubService rs={rs} />;
      }
    } else if (elemName == "social") {
      if (rs?.SocialMediaObj?.is_enabled === 1) {
        return <SubSocialMedia rs={rs} />;
      }
    } else if (elemName == "testimonials") {
      if (rs?.testimonialObj?.is_enabled === 1) {
        return <SubTestimonial rs={rs} />;
      }
    } else if (elemName == "google_review") {
      if(rs?.is_enabledGooglereview == 1){
        return <SubGoogleReview rs={rs} />;
      }
    }
  };

  const handleSubmits = async (e) => {
    e.preventDefault();
    setErrorMsgName("");
    setErrorMsgEmail("");
    setErrorMsgNo("");
    setErrorMsgText("");

    if (!appointmentName) {
      setErrorMsgName("Name is required");
      return;
    } else if (!isValidName(appointmentName)) {
      setErrorMsgName("Only alphabets allowed");
      return;
    }

    if (!appointmentEmail) {
      setErrorMsgEmail("Email is required");
      return;
    } else if (!isValidEmail(appointmentEmail)) {
      setErrorMsgEmail("Invalid email.");
      return;
    }

    if (!isValidPhoneNumber(appointmentContactNumber)) {
      setErrorMsgNo(
        "Invalid Mobile number."
      );
      return;
    }
    setIsSubmitAppointment(true);

    try {
      const dateObject = new Date(selectedDate);
      dateObject.setHours(12, 0, 0);
      const response = await axios.post(
        `${baseUrl}api/v1/appointment/MakeAppoinments`,
        {
          business_id: props.id,
          name: appointmentName,
          email: appointmentEmail,
          phone: appointmentContactNumber,
          date: dateObject.toISOString(),
          time: selectedTime,
          status: "pending",
          createdBy: props?.rs?.created_by,
          // note: textareaValue,
        },
        {
          headers: {
            _token: userData?.token,
          },
        }
      );
      if (response?.data?.success) {
        // socket.emit("appointment", rs?.created_by);
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsSubmitAppointment(false);
        setAppointmentName("");
        setAppointmentEmail("");
        setAppointmentContactNumber("");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }

      // console.log("response Appointmentsave", response?.data);
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsSubmitAppointment(false);
    }
  };

  return (
    <div id="temp1">
      <div className="main-temaplt" id="temp1">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 wtemplate p-0">
              <article className="imgsechere sec">
                <img
                  src={rs?.banner ? getImage(rs.banner) : banner}
                  className="mx-auto d-block img-fluid coverimghere "
                />

                <img
                  src={rs?.logo ? getImage(rs?.logo) : logo}
                  className="mx-auto d-block img-fluid logo-profile"
                />
              </article>
              <article className="personalinfo sec">
                <h3 className="username">{rs.sub_title}</h3>
                <p className="titleuser">{rs.designation}</p>
                <p className="companeyname">{rs.title}</p>

                {rs?.is_enabledGooglereview && rs?.is_enabledGooglereview ?(
                    <div className="reviewratingcontainer">
                    <p className="reviewrating">
                      {/* <StarRating value={props?.rating} totalStars={5} starSize="16px"  /> */}
                      <img src={Star1} style={{width:"20px", paddingRight:"5px"}}/>
                    <span className="ratingspan"> {rs.rating}</span> <span className="secondspanrating">/5</span>({rs.review} Reviews) 
                      </p>
                    </div>
                ) : ""}
            
              </article>




              {getBlocksOrder().map((val) => (val == undefined ? <></> : val))}



            {/* /////////////////////// Review US ///////////////////////////////////// */}
            {/* {rs?.is_enabledGooglereview == 1 && (
            <article className="makeappitmneets sec" style={{wordBreak:"break-all"}}>
            <h3 class="sectitle">Google Review</h3>
              <div>
                  <a href={rs.Googlereview} target="_blank" rel="noopener noreferrer">
                    <button 
                    style={{
                      background: "#065BA4",
                      color: "#fff",
                      width: "100%",
                      fontSize: "16px",
                      padding: "12px 0",
                      border: "none",
                      borderRadius: "6px",
                      }}
                    >Review us on Google</button>
                  </a>
              </div>
            </article>
             )} */}
            {/* //////////////////////////    Review US    ///////////////// */}



              <>
                <div
                  class="modal fade"
                  ref={createUserRef}
                  id="appotimnets"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header" style={{padding:"10px 20px 10px"}}>
                        <h5 class="modal-title" id="staticBackdropLabel">
                          Make Appointment
                        </h5>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        <form className="row">
                          <div class="col-12 mb-3">
                            <input
                              type="text"
                              class="form-control clear_string"
                              placeholder="Full Name"
                              value={appointmentName}
                              onChange={(e) =>
                                setAppointmentName(e.target.value)
                              }
                              style={{padding:"12px 25px"}}
                            />
                            <div
                              style={{
                                fontSize: "14px",
                                color: "red",

                                bottom: "70px",
                              }}
                            >
                              {errorMsgName}
                            </div>
                          </div>
                          <div class="col-12 mb-3">
                            <input
                              type="text"
                              class="form-control clear_string"
                              placeholder="Email"
                              value={appointmentEmail}
                              onChange={(e) =>
                                setAppointmentEmail(e.target.value)
                              }
                              style={{padding:"12px 25px"}}
                            />
                            <div
                              style={{
                                fontSize: "14px",
                                color: "red",
                                bottom: "70px",
                              }}
                            >
                              {errorMsgEmail}
                            </div>
                          </div>
                          <div class="col-12 mb-3">
                            <input
                              type="text"
                              class="form-control clear_string"
                              placeholder="Mobile No."
                              value={appointmentContactNumber}
                              onChange={(e) =>
                                setAppointmentContactNumber(e.target.value)
                              }
                              style={{padding:"12px 25px"}}
                            />
                            <div
                              style={{
                                fontSize: "14px",
                                color: "red",

                                bottom: "70px",
                              }}
                            >
                              {errorMsgNo}
                            </div>
                          </div>
                          <div class="mb-2 mt-2 col-md-12">
                            {isSubmitAppointment ? (
                              <div>
                                <CircularLoader size={20} />
                              </div>
                            ) : (
                              <button
                                onClick={handleSubmits}
                                class="btn btn-primary me-3 extmakeappointmentsubmitbtn"
                                type="button"
                              >
                                <i class="bi bi-check-circle-fill ms-2 "></i>
                                Submit
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                      <ToastContainer />
                    </div>
                  </div>
                </div>
                {/* <article className="sec makeappitmneets">
                  <h3 class="sectitle">Make Appointment</h3>
                  <div className="contact-form">
                    <div class="col-12 mb-3"><input type="date"
                      class="form-control clear_string"
                      placeholder="Date"
                      name="currentDate"
                      value={selectedDate}
                      onChange={(e) => setSelectedDate(e.target.value)} /></div>
                    <div class="col-12 mb-3">
                      <select class="form-select" aria-label="Default select example" onChange={(e) => setSelectedTime(e.target.value)}>
                        <option value="Select time" >Select time</option>
                        {appointmentdata?.map((val) => (
                          <option key={val?.startDate} value={`${changeTimeFormat(val?.startDate)} - ${changeTimeFormat(val?.endDate)}`}>
                            {`${changeTimeFormat(val?.startDate)} - ${changeTimeFormat(val?.endDate)}`}
                          </option>
                        ))}
                      </select>

                    </div>
                    <div class="col-12 mb-3">
                      <button className="btn btnmakeappitmnets" data-bs-toggle="modal" data-bs-target="#appotimnets">Make An Appointment</button>
                    </div>
                  </div>
                </article> */}
              </>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
             {appParams==null&&<> 
              <div className="fixedd">
                <article className="fixedfooter sec">
                  <div className="footerleft">
                    <div
                      className="qrcodeshaere"
                      data-bs-toggle="modal"
                      data-bs-target="#qrscan"
                    >
                      <i class="fa-solid fa-qrcode"></i>
                    </div>
                    {/* <div className="share">
                      <i class="fa-solid fa-arrow-up"></i>
                    </div> */}
                  </div>
                  <div className="addtocinatct" onClick={downloadVCard}>
                    +Add To Contact
                  </div>
                </article>
              </div></>}
            </div>
          </div>
        </div>
      </div>
     

      <div
        class="modal fade"
        id="qrscan"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          {/* <div class="modal-content"> */}
          <div class="modal-body" style={{ position: "relative" }}>
            {/* <article className="personalinfo modal-info "> */}
            {/* {rs && rs?.logo && (
              <img
                className="qr-logo-image"
                src={
                  rs?.logo
                    ? `${environmentVariables?.apiUrl}uploads/${rs?.logo}`
                    : whitecircle
                }
              />
            )} */}
            {rs && rs.logo ? (
              <img
                className="qr-logo-image"
                src={`${environmentVariables?.apiUrl}uploads/${rs.logo}`}
              />
            ) : (
              <img
                className="qr-logo-image"
                src={whiteCircle}
                alt="White Circle"
              />
            )}
            {/* <QRCode value={`${window.location.origin}/${rs?.slug}`} /> */}
            <QrCodeGenerator />
            {/* <QRCodes businessObj={businessObjData} /> */}

            {/* <p className="companeyname">{rs.sub_title || "Company Name"}</p> */}
            {/* </article> */}
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Theme1Ext;
