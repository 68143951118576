import React, { useEffect, useContext, useState, useRef, useCallback } from "react";
import { userContext } from "../../../../context/userContext";
import { environmentVariables } from "../../../../config/env.config";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import "./Theme22.css";
import QrCodeGenerator from "../theme6/QRCode";

import whiteCircle from "../../../../../src/Images/white-circle.png";
import Radar from "radar-sdk-js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from "styled-components";
import CabsTab from "./CabsTab";
import AvilableCabTab from "./AvilableCabTab";
import CabAbout from "./CabAbout";
import TermAndCondition from "./TermAndCondition";
import CabContactInfo from "./CabContactInfo";
import CabBanner from "./../../../../Images/Cabdefaultbanner.png";
import Cablogo from "./../../../../Images/Cabdefaultlogo.png";
import CabIcon from "./../../../../Images/Defaultcabtab.png";
import  AvilableCab from "./../../../../Images/Defaultavilablecabtab.png";
import  AboutIcon from "./../../../../Images/Defaultabouttab.png";
import  COntactIcon from "./../../../../Images/DefaultContacttab.png";
import ExtCabsTab from "./ExtCabsTab";




const Theme22Ext = (props) => {
  const rs = props.rs;
  const [businessLink, setBusinessLink] = useState("");
  const [storedData, setStoredData] = useState(
    localStorage.getItem("businessObj")
  );
  const { userData } = useContext(userContext);
  let { param } = useParams();
  const [pparam, setPparam] = useState([]);
  const searchParams = new URLSearchParams(window.location.search);
  const location = useLocation();
  const [galleryObject, setGalleryObject] = useState(null);
  const currentDate = new Date();
  const [order, setOrder] = useState(null);
  const [minDate, setMinDate] = useState("");
  const activeTabParam = searchParams.get("activeTab");
  const [activeTab, setActiveTab] = useState("");
  const [isReviewDrawerVisible, setIsReviewDrawerVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [userReview, setUserReview] = useState();
  const openReviewDrawer = useCallback(() => setIsReviewDrawerVisible(true), []);
  // const closeReviewDrawer = useCallback(() => setIsReviewDrawerVisible(false), []);
  const [formData, setFormData] = useState(null);
  const [isOrderReviewVisible, setIsOrderReviewVisible] = useState(false);

  const closeOrderReview = () => {
    setIsOrderReviewVisible(false);
  };


  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setMinDate(formattedDate);
  }, []);


  let ff = rs?.contactInfo?.content;
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }

  useEffect(() => {
    const path = location?.pathname;
    const pathParts = path.split("/");
    setPparam(pathParts);
  }, [location]);


  useEffect(() => {
    if (storedData != "undefined") {
      const businessData = JSON.parse(storedData);
      const slugb = businessData?.slug;
      const originUrl = window.location.origin;
      setBusinessLink(`${originUrl}/${slugb}`);
    }
  }, [storedData]);


  useEffect(() => {
    if (rs?.menu?.is_enabled === 1) {
      setActiveTab("menu");
    } else {
      setActiveTab("cab");
    }
  }, [rs]);

  let contactInfo = props?.rs?.contactInfo?.content;

  // parsing into string
  if (contactInfo?.length) {
    contactInfo = JSON.parse(contactInfo);
  }
  // parsing into object
  if (contactInfo?.length) {
    contactInfo = JSON.parse(contactInfo);
  }


  let termsConditions = props?.rs?.cabTermAndCondition?.content
  // parsing into string
  if (termsConditions?.length) {
    termsConditions = JSON.parse(termsConditions);
  }
  // parsing into object
  if (termsConditions?.length) {
    termsConditions = JSON.parse(termsConditions);
  }

  let socialdt = props?.rs?.SocialMediaObj?.content
  // parsing into string
  if (socialdt?.length) {
    socialdt = JSON.parse(socialdt);
  }
  // parsing into object
  if (socialdt?.length) {
    socialdt = JSON.parse(socialdt);
  }
  console.log(socialdt,"erfc")


  useEffect(() => {
    getBlockOrder();
    if (rs?.newGalleryImages) {
      setGalleryObject(rs?.newGalleryImages);
    }
    if (rs?.appointmentObj && rs?.appointmentObj?.content) {
      try {

      } catch (err) {
        console.log(err);
      }
    }
  }, [rs]);
  const getBlockOrder = async () => {
    // const handleGetBlock = async () => {
    try {
      let id = props?.id;

      if (id) {
        let { data: get } = await axios.get(
          `${environmentVariables?.apiUrl}api/v1/business/FetchBlock?business_id=${id}`,
          {
            headers: {
              _token: userData?.token,
            },
          }
        );
        setOrder(get?.data?.order);
      }

    } catch (err) {
      console.log(err);
    }
    // };
  };

  const [orderItems, setOrderItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [enableCreateOrder, setEnableCreateOrder] = useState(false);



  useEffect(() => {
    if (enableCreateOrder) {
      createOrder();
      whatsappclick();
    }
  }, [enableCreateOrder])


  const createOrder = async () => {
    try {
      const requestData = {
        businessId: props?.id,
        BusinessName: props?.rs?.slug,
        userId: props?.rs?.created_by,
        totalAmount,
        ...formData,
        items: orderItems,
      };

      // Make API call to create order

     
      toast.success("Your order has been successfully placed", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setFormData(null);
      setOrderItems([]);
      closeOrderReview();
      setActiveTab("menu")
    } catch (error) {
      console.error("Error creating order:", error);
      toast.error("Something went wrong while creating order", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };





  const itemsInfo = orderItems.map(item => `${item.quantity}x ${item.name}: ₹${item.price}`).join('\n');
  const whatsappclick = () => {
    const phoneNumber = contactInfo?.contactInfoPhone;
    // const phoneNumber = "8929099255";
    const { name, phone, address } = formData;
    const message = encodeURIComponent(`Hi, I'd like to place an order.\nCustomer details:\nName: ${name}\nPhone: ${phone}\nAddress: ${address}\nOrder Detail:-\n${itemsInfo}\nTotal Amount:-${totalAmount}`);
    const apiUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;
    window.open(apiUrl, '_blank');

  };

  const renderContent = () => {
    switch (activeTab) {
      case "cab":
        return <div> <ExtCabsTab busdata={rs}/> </div>;
      case "avilablecab":
        return <div><AvilableCabTab  cabs={rs?.cabproduct}/> </div>;
      case "about":
        return <div><CabAbout abouttext={rs}/></div>;
      case "termcondition":
          return <div><TermAndCondition  termsConditions={termsConditions} /></div>;
      case "contact":
            return <div><CabContactInfo contdata={ff} socialdata={socialdt} /></div>;
      default:
        return null;
    }
  };

  const getTabClass = (tabName) => {
    return activeTab === tabName
      ? "flex bg-Cabdefaultcolor px-3 p-2 border-2 border-none items-center rounded mx-2 cursor-pointer"
      : "flex bg-transparent p-2 px-3 border-1 border-Bordergray items-center rounded mx-2 cursor-pointer";
  };

  const getImage = (image) => {
    if (image.includes("base64")) {
      return image;
    } else {
      return `${environmentVariables?.apiUrl}uploads/${image}`;
    }
  };

  console.log(rs,"theme22ext")


  //{`${isVisible ? "blur-background " : ""}main-temaplt`}
  return (
    <div id="temp22">
      <div className="main-temaplt" id="temp22">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 wtemplate p-0">
              {/******************** Cab Template  *********/}
           
              <div>
              <div className="relative bannerimg22">
                <img className="w-full object-fill" src={rs?.banner ? getImage(rs.banner) : CabBanner}/>
                <div className="flex  absolute items-center text-center" style={{top:"50px", flexDirection:"column", lineHeight:"10px"}}>
                  <img className="w-fiftypx pb-2 " style={{maxWidth:"80px", borderRadius:"100px"}} src={rs?.logo ? getImage(rs.logo) : Cablogo} />
                  <div className="font-DM text-2xl text-white uppercase break-all">{rs.title}</div>
                </div>
              </div>
          <div className="display-flex overflow-scroll" style={{background: "black"}} >
            <div className="flex bg-black pb-4 p-2" style={{width:"max-content"}}>
                <div 
                // className="flex bg-Cabdefaultcolor p-2 border-2 border-none items-center rounded mx-2 cursor-pointer"
                className={getTabClass("cab")}
                onClick={()=>setActiveTab("cab")}>
                  <img src={CabIcon} className="h-twelve"/>
                  <div className="px-1 font-Poppins font-medium text-white">Cab</div>
                </div>
                {rs?.is_CabProduct == 1 && (
                 <div 
                //  className="flex bg-transparent p-2 border-1 border-Bordergray items-center rounded mx-2 cursor-pointer"
                   className={getTabClass("avilablecab")}
                   onClick={()=>setActiveTab("avilablecab")}>
                  <img src={AvilableCab} className=" h-twelve"/>
                  <div className="px-1 font-Poppins font-medium text-white">Available Cabs</div>
                </div>
                )}

                <div
                //  className="flex bg-transparent p-2 border-1 border-Bordergray items-center rounded mx-2 cursor-pointer"
                className={getTabClass("about")}
                    onClick={()=>setActiveTab("about")}>
                  <img src={AboutIcon} className=" h-twelve"/>
                  <div className="px-1 font-Poppins font-medium text-white">About</div>
                </div>

                {rs?.cabTermAndCondition?.is_enabled == 1 && (
                <div 
                // className="flex bg-transparent p-2 border-1 border-Bordergray items-center rounded mx-2 cursor-pointer"
                className={getTabClass("termcondition")}
                    onClick={()=>setActiveTab("termcondition")}>
                  <img src={COntactIcon} className="h-twelve"/>
                  <div className="px-1 font-Poppins font-medium text-white">Term & Conditions</div>
                </div> 
                )}

                {rs?.contactInfo?.is_enabled == 1 && (
                <div
                //  className="flex bg-transparent p-2 border-1 border-Bordergray items-center rounded mx-2 cursor-pointer"
                className={getTabClass("contact")}
                    onClick={()=>setActiveTab("contact")}>
                  <img src={COntactIcon} className="h-twelve"/>
                  <div className="px-1 font-Poppins font-medium text-white">Contact</div>
                </div>
                )} 
            
            </div>
          </div>
          <div className="px-2 bg-black" style={{height: "calc(100vh - 262px)", overflowY: "scroll"}}>{renderContent()}</div>
            </div>
            
              {/****************  Cab Template  ************/}
           
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="qrscan"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-body" style={{ position: "relative" }}>
            {rs && rs.logo ? (
              <img
                className="qr-logo-image"
                src={`${environmentVariables?.apiUrl}uploads/${rs.logo}`}
              />
            ) : (
              <img
                className="qr-logo-image"
                src={whiteCircle}
                alt="White Circle"
              />
            )}
            <QrCodeGenerator />
          </div>
        </div>
      </div>
      <ToastContainer />

    </div>
  );
};

export default Theme22Ext;
