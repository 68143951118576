import PageName from "../Utils/PageName";
import Them from "./BusinessPages/Them";
import Details from "./BusinessPages/Details";
import ResumeDetails from "./BusinessPages/ResumeDetails";
import SeoPage from "./BusinessPages/SeoPage";
import ResumePage from "./BusinessPages/ResumePage";
import ChangeBlock from "./BusinessPages/ChangeBlock";
import QrCodeGenerator from "./QRCode";
import QRCodes from "./BusinessPages/QRCodes";
import QRCode from "qrcode.react";
import Coockie from "./BusinessPages/Coockie";
import { useLocation, useNavigate } from "react-router";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { environmentVariables } from "../../config/env.config";
import React, { useContext, useEffect, useRef, useState } from "react";
import { userContext } from "../../context/userContext";
import { Link } from "react-router-dom";
import { businessObjContext } from "../../context/businessObjContext";
import Coptimg from "../../Images/copymain.png";
import copy from "clipboard-copy";
import qrcode from "../../Images/qrmain.png";
import CircularLoader from "../../Backend/Components/CircularLoader/CircularLoader";

import {
  businessContext,
  isBusinesCreatedContext,
} from "../../context/businessContext";
// import CircularLoader from "../Components/CircularLoader/CircularLoader";
import "./../Pages/Updatedcss/BusinessChangeBlockUpdatedStyle.css";

import Cookies from "js-cookie";
const Business = ({ setThemeNumber, themeNumber }) => {
  const [businessId, setBusinessId] = useState(0);
  const [selectedTheme, setSelectedTheme] = useState("theme1");
  const [businessName, setBusinessName] = useState("");
  const [isStatus, setIsStatus] = useState(false);
  const [mobilePreviewLoading, setMobilePreviewLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { isBusinessCreated, setIsBusinessCreated } = useContext(
    isBusinesCreatedContext
  );
  const [loaderBusinessCreate, setLoaderBusinessCreate] = useState(false);
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);
  const storedData = localStorage.getItem("businessObj");
  const [businessLink, setBusinessLink] = useState("");
  const [themeColor, setThemeColor] = useState(null);
  const [businessObj, setBusinessObj] = useState({});
  const { businessObjData, setBusinessObjData, resumeData, setResumeData } =
    useContext(businessObjContext);
  const { businessData } = useContext(businessContext);
  const [isChecking, setIsChecking] = useState(null);
  const [isLoader, setIsloader] = useState(true);
  const [appointmentSlotDuration, setAppointmentSlotDuration] = useState({
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
    sunday: "",
  });
  const [activeTab, setActiveTab] = useState("tab1"); // Initial active tab
  const fronturl = environmentVariables?.frntUrl;
  const httpProtocol = environmentVariables?.httpProtocol;

  const [businesID, setbusinesID] = useState(
    localStorage.getItem("business_id")
  );
  const prevIdRef = useRef(null);

  useEffect(() => {
    if (businessObjData) {
      if (businessObjData?.theme_color && businessObjData?.theme_color) {
        setThemeColor(businessObjData?.theme_color?.split("-")[0]);
        setThemeNumber(businessObjData?.theme_color?.split("e").pop());
      }
    }
  }, [businessObjData]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("create") == 1) {
      // setThemeNumber("theme6");
      // setThemeColor("theme6");
      setIsloader(false);
      setActiveTab("tab1");
      setIsChecking(null);
      setBusinessObjData(null);
      setResumeData(null);
    }

    // if (params.get("p") == "themes") {
    //   setActiveTab("tab1");
    // } else if (params.get("p") == "details") {
    //   setActiveTab("tab2");
    // } else if (params.get("p") == "blocks") {
    //   setActiveTab("tab3");
    // } else {
    //   setActiveTab("tab1");
    // }
    //   if (params.get("id") && params.get("id") !== prevIdRef.current) {
    //     prevIdRef.current = params.get("id");
    //     setBusinessId(params.get("id"));
    //     setIsChecking(params.get("id"));
    //     handleFetchById(params.get("id"), "C92");
    //   }
    // }, [businessId, location.search, themeColor, activeTab]);

    if (params.get("id")) {
      setBusinessId(params.get("id"));
      setIsChecking(params.get("id"));
      handleFetchById(params.get("id"));
      handleResFetchById(params.get("id"))
    }
  }, [businessId, location.search, themeColor, activeTab]);

  useEffect(() => {
    if (storedData != "undefined") {
      const businessData = JSON.parse(storedData);
      const slugb = businessData?.slug;
      const originUrl = window.location.origin;
      // console.log(businessData.slug_subdomain,"567yuhj")
      const slug_subdomain = businessData?.slug_subdomain
      let linkToCopy = (slug_subdomain != null)? httpProtocol +slug_subdomain+"?pswutlzoq=install":(slugb !=null)? fronturl+ slugb+"?pswutlzoq=install":"/";

      setBusinessLink(linkToCopy);
      setBusinessObj(businessData);
    }
  }, [storedData, businessObjData]);

  // useEffect(() => {
  //   if (isBusinessCreated && isBusinessCreated?.businessId) {
  //     navigate(`?id=${isBusinessCreated?.businessId}`);
  //   }
  // }, [isBusinessCreated]);

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(businessLink)
      .then(() => {
        setCopied(true);
      })
      .catch((error) => {
        console.error("Failed to copy link: ", error);
      });
  };

  const handleDelete = () => {
    const deleteBusinessEndpoint = "/api/business/deleteBusiness";
    const postData = {
      id: 14,
    };
    axios
      .delete(`${baseUrl}${deleteBusinessEndpoint}`, postData)
      .then((response) => {
      })
      .catch((error) => {
        console.error("Error deleting business:", error);
      });
  };

  const clearData = () => {
    setBusinessName("");
    setSelectedTheme("");
  };

  const handleSubmit = async(e) => {
    if (activeTab == "tab1" && isChecking == null) {
      try {
        e.preventDefault();
        if (businessName == "" || businessName?.trim() == "") {
          toast.error("Please provide Business Name", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          return;
        }else if(businessName?.length >63  || businessName?.trim()?.length>63){
          toast.error("The business name must not exceed 63 characters.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          return;
      }
        if (selectedTheme == "" || selectedTheme?.trim() == "") {
          toast.error("Theme is mandatory", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          return;
        }

        let obj = {
          theme: selectedTheme,
          // theme_color: selectThemeColor + `-${selectedTheme}`,
          theme_color: selectedTheme,
        };

        setLoaderBusinessCreate(true);
        if (isChecking == null) {
          obj.business_title = businessName;
        } else {
          obj.businessId = businessObj?.id;
        }
        setIsStatus(true);
        if (isChecking == null) {
          try {
            const res = await axios.post(`${baseUrl}api/v1/business/create`, obj, {
              headers: {
                _token: userData?.token,
              },
            });
  
            setBusinessObjData(null);
            if (res?.data?.success) {
              toast.success(res?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
  
              let tempObj = {};
              tempObj.businessId = res?.data?.businessId;
              tempObj.businessName = businessName;
              setIsBusinessCreated(tempObj);
              setLoaderBusinessCreate(false);
              setAppointmentSlotDuration([]);
              setActiveTab("tab2");
              setThemeColor(selectedTheme);
              navigate(`?id=${res?.data?.businessId}`);
              clearData();
  
              // Make the additional API call to Cloudflare through the server proxy
              try {
                await axios.post(`${baseUrl}api/v1/business/createsubdomain`, {
                  name: res?.data?.slug,
                },{
                  headers: {
                    _token: userData?.token,
                  },
                });
                // toast.success("DNS record created successfully", {
                //   position: toast.POSITION.TOP_RIGHT,
                //   autoClose: 1000,
                // });
              } catch (dnsError) {
                console.log("dnsError====>>>>", dnsError.message);
                // toast.error(`DNS record creation failed: ${dnsError.message}`, {
                //   position: toast.POSITION.TOP_RIGHT,
                //   autoClose: 2000,
                // });
              }
            }
          } catch (err) {
            toast.error(err?.response?.data?.message?.message || err?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            setLoaderBusinessCreate(false);
          } finally {
            setIsStatus(false);
            setLoaderBusinessCreate(false);
          }
        }
      } catch (err) {
        toast.error(err.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        return;
      }
    } else {
      let obj = {
        theme: selectedTheme,
        theme_color: selectedTheme,
      };
      if (isChecking == null) {
        obj.business_title = businessName;
      } else {
        obj.businessId = businessObj?.id;
      }
      axios.post(`${baseUrl}api/v1/business/edit`, obj, {
        headers: {
          _token: userData?.token,
        },
      })

      if (activeTab == "tab1") {
        setActiveTab("tab2");
      } else if (activeTab == "tab2") {
        setActiveTab("tab3");
      } else if (activeTab == "tab3") {
        setActiveTab("tab4");
      }
    }
  };
  const handleFetchById = (id) => {
    try {
      setMobilePreviewLoading(true);
      if (id != undefined) {
        axios
          .get(`${baseUrl}api/v1/business/fetchBusinessById?id=${id}`, {
            headers: {
              _token: userData?.token,
            },
            // withCredentials: true,
          })
          .then((res) => {
            if (res?.data?.success) {
              setBusinessObjData(res?.data?.data);
              // setBusinessDataObj(res?.data?.data);
              // setBusinessData(res?.data?.data);
              localStorage.setItem(
                "businessObj",
                JSON.stringify(res?.data?.data)
              );
              localStorage.setItem("business_id", res?.data?.data?.id);
              setIsloader(false);
              setMobilePreviewLoading(false);
            }
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            setMobilePreviewLoading(false);
          });
      }
    } catch (err) {
      toast.error(err, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }
  };
  const handleResFetchById = (id) => {
    try {
      setMobilePreviewLoading(true);
      if (id != undefined) {
        axios
          .get(`${baseUrl}api/v1/resume/fetchResumeById?id=${id}`, {
            headers: {
              _token: userData?.token,
            },
            // withCredentials: true,
          })
          .then((res) => {
            if (res?.data?.success) {
              setResumeData(res?.data?.data);
              setIsloader(false);
              setMobilePreviewLoading(false);
            }else {
              // Handle unsuccessful response
              console.log(res?.data?.message); // Log error message
              setResumeData({}); // Set resumeData to an empty object
              setMobilePreviewLoading(false);
            }
          })
          .catch((err) => {
            console.log(err?.response?.data?.message);
            setMobilePreviewLoading(false);
            setResumeData({});
          });
      }
    } catch (err) {
      toast.error(err, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }
  };

  // useEffect(() => {
  //   console.log(businessObjData,"businessObjData#$%1 1")
  //   if (businessObjData) {
  //     handleFetchById(businessObjData?.id);
  //     return;
  //   }
  // }, [location]);

  // useEffect(() => {
  //   // console.log(isBusinessCreated,"isBusinessCreated@#$@$  12 ")
  //   if (isBusinessCreated && isBusinessCreated?.businessId) {
  //     handleFetchById(isBusinessCreated?.businessId);
  //     handleResFetchById(isBusinessCreated?.businessId)
  //     // setActiveTab("tab2");
  //   } else {
  //     // setIsloader(false)
  //   }
  // }, [isBusinessCreated]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    // if (tab == "tab1") {
    //   navigate("?p=themes");
    // } else if (tab == "tab2") {
    //   navigate("?p=details");
    // } else if (tab == "tab3") {
    //   navigate("?p=blocks");
    // }
  };
  const copyToClipboard = () => {
    copy(businessLink);
    toast.success("Link copied", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
  };
  return (
    <>
      {isLoader ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "55%",
            display: "flex",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <CircularLoader size={40} />
        </div>
      ) : (
        <>
          <PageName PageName="Business Information" />
          <div className="app-titlepage ">
            <div className="col-12 col-lg-2 StepsMobileviewstyling">
              <div
                className={`tab-item ${activeTab === "tab1" ? "active" : ""}`}
                onClick={() => handleTabClick("tab1")}
              >
                <p>Step 1</p>
                Select Theme
              </div>
            </div>

            <div className="col-12 col-lg-2 StepsMobileviewstyling">
              {businessObjData && businessObjData?.id ? (
                <div
                  className={`tab-item ${activeTab === "tab2" ? "active" : ""}`}
                  onClick={() => {
                    isChecking == null
                      ? toast.info(
                          "Please enter business name then select theme and click create to add details",
                          {
                            position: toast.POSITION.TOP_RIGHT,
                          }
                        )
                      : handleTabClick("tab2");
                  }}
                >
                  <p>Step 2</p>
                  Add Details
                </div>
              ) : (
                <div></div>
              )}
            </div>

            {businessObjData && (businessObjData?.theme_color !== "theme14" &&  businessObjData?.theme_color !== "theme20" && businessObjData?.theme_color !== "theme22") ?(
             <div className="col-12 col-lg-2 StepsMobileviewstyling">
             { businessObjData?.id ? (
               <div
                 className={`tab-item ${activeTab === "tab3" ? "active" : ""}`}
                 onClick={() => {
                   isChecking == null
                     ? toast.info(
                         "Please enter business name then select theme and click create to add details",
                         {
                           position: toast.POSITION.TOP_RIGHT,
                         }
                       )
                     : handleTabClick("tab3");
                 }}
               >
                 <p>Step 3</p>
                 Change Blocks
               </div>
             ) : (
               ""
             )}
           </div> 
           ) : (
          <div className="col-12 col-lg-2 StepsMobileviewstyling"> </div>
          )}

           
            {businessObjData && (businessObjData?.theme_color !== "theme14" &&  businessObjData?.theme_color !== "theme20" && businessObjData?.theme_color !== "theme22") ? (
            <div className="col-12 col-lg-3 StepsMobileviewstyling">
              {businessObjData?.id ? (
                <div
                  className={`tab-item ${activeTab === "tab4" ? "active" : ""}`}
                  onClick={() => {
                    isChecking == null
                      ? toast.info(
                          "Please enter business name then select theme and click create to add details",
                          {
                            position: toast.POSITION.TOP_RIGHT,
                          }
                        )
                      : handleTabClick("tab4");
                  }}
                >
                  <p>Step 4</p>
                 Resume
                </div>
              ) : (
                ""
              )}
            </div>
            ) : (
           <div className="col-12 col-lg-2 StepsMobileviewstyling"> </div>
            )}
            <div className="col-12 col-lg-1 CopyQrNextbtnWrapper">
              <div className="bothbtng CopyQrNextbtnContainer">
                <button className="BusinessCopyqrButton">
                  <img
                    src={Coptimg}
                    className="mx-auto d-block "
                    onClick={copyToClipboard}
                  />
                </button>
                <button
                  disabled={isChecking == null ? true : false}
                  className="BusinessCopyqrButton"
                >
                  <img
                    src={qrcode}
                    className="mx-auto d-block"
                    data-bs-toggle="modal"
                    data-bs-target="#myQR"
                  />
                </button>

                <div className="col-12 col-lg-2 BusinessCpyqrnextButton">
                  {loaderBusinessCreate ? (
                    <CircularLoader />
                  ) : (
                    // <></>
                    // <div
                    //   onClick={
                    //     businessObjData?.theme_color !== "theme14" 
                    //       ? handleSubmit 
                    //       : null
                    //   }
                    //   id="CreateandNextButton"
                    //   className={
                    //      activeTab == "tab4" || businessObjData?.theme_color === "theme14"
                    //       ? "nextbtndisable tab-item L351"
                    //       : "nextbtn tab-item L351"
                    //   }
                    // >
                    //   {isChecking == null && activeTab == "tab1"
                    //     ? "Create"
                    //     : "Next"}
                    // </div>
                    <div
                    onClick={
                      // (businessObjData?.theme_color !== "theme14" && businessObjData?.theme_color !== "theme20" && activeTab !== "tab4") 
                      (activeTab !== "tab4") && ((businessObjData?.theme_color === "theme14" && activeTab === "tab2") ||
                      (businessObjData?.theme_color === "theme20" && activeTab === "tab2"))? null:
                      (activeTab !== "tab4")?handleSubmit 
                        : null
                    }
                    id="CreateandNextButton"
                    className={
                      (activeTab === "tab4" || 
                      (businessObjData?.theme_color === "theme14" && activeTab === "tab2") ||
                      (businessObjData?.theme_color === "theme20" && activeTab === "tab2"))
                        ? "nextbtndisable tab-item L351"
                        : "nextbtn tab-item L351"
                    }
                  >
                    {isChecking == null && activeTab == "tab1" ? "Create" : "Next"}
                  </div>

                  )}
                </div>
              </div>
            </div>
            {/* <div className="col-12 col-lg-2">
              {loaderBusinessCreate ? (
                <CircularLoader />
              ) : (
                <div
                  onClick={handleSubmit}
                  className={
                    activeTab == "tab3"
                      ? "nextbtndisable tab-item L351"
                      : "nextbtn tab-item L351"
                  }
                >
                  {isChecking == null && activeTab == "tab1"
                    ? "Create"
                    : "Next"}
                </div>
              )}
            </div> */}

            {/* <div className="col-md-12 mb-3">
          <div className="tile-a">
            <ul className="tab-header">
              <li
                className={`tab-item ${activeTab === "tab1" ? "active" : ""}`}
                onClick={() => handleTabClick("tab1")}
              >
                Theme
              </li>
              {businessObjData && businessObjData?.id ? (
                <li
                  className={`tab-item ${activeTab === "tab2" ? "active" : ""}`}
                  onClick={() => handleTabClick("tab2")}
                >
                  Details
                </li>
              ) : (
                <div></div>
              )}
              {businessObjData && businessObjData?.id ? (
                <li
                  className={`tab-item ${activeTab === "tab3" ? "active" : ""}`}
                  onClick={() => handleTabClick("tab3")}
                >
                  Change Blocks
                </li>
              ) : (
                ""
              )}
             
            </ul>
          </div>
        </div> */}
          </div>

          <div className="row ">
            <div className="col-12 col-lg-12">
              <div className="tile-a">
                <div className="tab-content">
                  {activeTab === "tab1" && (
                    <>
                      <div
                        className={`tab-item ${
                          activeTab === "tab1" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("tab1")}
                        id="MobileActiveSteps"
                      >
                        <p>Step 1</p>
                        Select Theme
                      </div>
                      <Them
                        mobilePreviewLoading={mobilePreviewLoading}
                        setActiveTab={setActiveTab}
                        isStatus={isStatus}
                        setIsStatus={setIsStatus}
                        businessName={businessName}
                        setBusinessName={setBusinessName}
                        selectedTheme={selectedTheme}
                        setSelectedTheme={setSelectedTheme}
                        setThemeNumber={setThemeNumber}
                        setThemeColor={setThemeColor}
                        themeColor={themeColor}
                        themeNumber={themeNumber}
                        businessObj={businessObjData}
                        isChecking={isChecking}
                        setIsChecking={setIsChecking}
                        activeTab={activeTab}
                        isLoader={isLoader}
                      />
                    </>
                  )}

                  {activeTab === "tab2" && (
                    <>
                      <div
                        id="MobileActiveSteps"
                        className={`tab-item ${
                          activeTab === "tab2" ? "active" : ""
                        }`}
                      >
                        <p>Step 2</p>
                        Add Details
                      </div>
                      <Details
                        appointmentSlotDuration={appointmentSlotDuration}
                        setAppointmentSlotDuration={setAppointmentSlotDuration}
                        mobilePreviewLoading={mobilePreviewLoading}
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        themeColor={themeColor}
                        handleFetchById={handleFetchById}
                        themeNumber={themeNumber}
                        businessObj={businessObjData}
                      />
                    </>
                  )}
                  {activeTab === "tab3" && (
                    <>
                      <div
                        id="MobileActiveSteps"
                        className={`tab-item ${
                          activeTab === "tab3" ? "active" : ""
                        }`}
                        //  onClick={() => {
                        //    isChecking == null
                        //      ? toast.info(
                        //          "Please enter business name then select theme and click create to add details",
                        //          {
                        //            position: toast.POSITION.TOP_RIGHT,
                        //          }
                        //        )
                        //      : handleTabClick("tab3");
                        //  }}
                      >
                        <p>Step 3</p>
                        Change Blocks
                      </div>
                      <ChangeBlock
                        mobilePreviewLoading={mobilePreviewLoading}
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        themeColor={themeColor}
                        themeNumber={themeNumber}
                        businessObj={businessObjData}
                      />
                    </>
                  )}
                  {activeTab === "tab4" && (
                       <>
                       <div
                         id="MobileActiveSteps"
                         className={`tab-item ${
                           activeTab === "tab2" ? "active" : ""
                         }`}
                       >
                         <p>Step 4</p>
                         Resume
                       </div>
                       <ResumeDetails
                         appointmentSlotDuration={appointmentSlotDuration}
                         setAppointmentSlotDuration={setAppointmentSlotDuration}
                         mobilePreviewLoading={mobilePreviewLoading}
                         setActiveTab={setActiveTab}
                         activeTab={activeTab}
                         themeColor={themeColor}
                         themeNumber={themeNumber}
                         resumeData={resumeData}
                         businessObj={businessObjData}
                       />
                     </>
                  )}
                  {activeTab === "tab5" && (
                    <Coockie businessObj={businessObjData} />
                  )}
                  {activeTab === "tab6" && (
                    <QRCodes businessObj={businessObjData} />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="myQR"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content MobileBusinessQrcode">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Business QR
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="mb-2 col-md-12">
                    <QrCodeGenerator url={businessLink} />
                    {/* <QRCode
                      value={businessLink}
                      style={{
                        height: "320px",
                        width: "320px",
                        margin: "auto",
                        display: "block",
                        border: "15px solid #fff",
                        boxShadow: "0px 0px 10px 2px #00000040",
                        borderRadius: "20px",
                      }}
                    /> */}

                    <h4 className="linkcopunextwindiow mt-4 flex-center">
                      <a
                        className="link-qr"
                        href={businessLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {businessLink}
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Business;