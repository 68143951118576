import React, { useContext, useState, useRef } from "react";
import { userContext } from "../../../../context/userContext";
import { environmentVariables } from "../../../../config/env.config";
import { useEffect } from "react";
import "./Theme22.css";
import axios from "axios";
import { currencyContext } from "../../../../context/currencyContext.js";
import styled from "styled-components";
import CabBanner from "./../../../../Images/Cabdefaultbanner.png";
import Cablogo from "./../../../../Images/Cabdefaultlogo.png";
import CabIcon from "./../../../../Images/Defaultcabtab.png";
import  AvilableCab from "./../../../../Images/Defaultavilablecabtab.png";
import  AboutIcon from "./../../../../Images/Defaultabouttab.png";
import  TermCondIcon from "./../../../../Images/Defaultterm&contab.png";
import  COntactIcon from "./../../../../Images/DefaultContacttab.png";
import AvilableCabTab from "./AvilableCabTab";
import CabAbout from "./CabAbout.js";
import TermAndCondition from "./TermAndCondition.js";
import CabContactInfo from "./CabContactInfo.js";
import CabsTab from "./CabsTab.js";

// import "./Template10.css";
const Theme22 = (props) => {
  const { userData } = useContext(userContext);
  const { currencyObjData } = useContext(currencyContext);
  const [activeTab, setActiveTab] = useState("avilablecab");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    getBlockOrder();
  }, [props?.businessObj, props.businessId, props.orderOfBlocks]);
  const getBrandNameForSocial = (brand) => {
    if (brand == "gmail") {
      return "google";
    } else return `${brand}`;
  };
  const getBlockOrder = async () => {
    // const handleGetBlock = async () => {
    if (props.orderOfBlocks) {
      let orders = {};
      props.orderOfBlocks.map((val) => {
        if (val.content == "Description") {
          orders["description"] = val?.position;
        } else if (val.content == "Contact Info") {
          orders["contact_info"] = val?.position;
        } else if (val.content == "Appointment") {
          orders["appointment"] = val?.position;
        } else if (val.content == "Service") {
          orders["service"] = val?.position;
        } else if (val.content == "Gallery") {
          orders["gallery"] = val.position;
        } else if (val.content == "Contact Form") {
          orders["contact_form"] = val.position;
        } else if (val.content == "Testimonials") {
          orders["testimonials"] = val.position;
        } else if (val.content == "Social") {
          orders["social"] = val.position;
        } else if (val.content == "Business Hour") {
          orders["business_hour"] = val.position;
        }
      });
      props.setOrder(orders);
    } else {
      try {
        let id = props?.businessObj?.id || props.businessId;
        if (props?.businessObj?.id) {
          let { data: get } = await axios.get(
            `${environmentVariables?.apiUrl}api/v1/business/FetchBlock?business_id=${id}`,
            {
              headers: {
                _token: userData?.token,
              },
              // withCredentials: true,
            }
          );
          // console.log(get?.data?.order, "order data");
          props.setOrder(get?.data?.order);
        }

        // console.log(get.data.order,"Getetgwef")
      } catch (err) {
        console.log(err, "error in template");
      }
    }
    // };
  };


  const getCorrectDay = (day) => {
    const dayMappings = {
      "WednesDay": "Wednesday",
      "thrusdayDay": "Thursday",
      "fridayDay": "Friday",
      "saturdayDay": "Saturday",
      "sundayDay": "Sunday"
    };

    return dayMappings[day] || day;
  };

  const getOpeningHours = (businessHourObject) => {
    let daysOfWeek = ["Monday", "Tuesday", "WednesDay", "thrusdayDay", "fridayDay", "saturdayDay", "sundayDay"];
    let startDay = null;
    let endDay = null;
    let closedDays = [];

    // Find the first and last true values in businessHourObject
    for (let day of daysOfWeek) {
      if (businessHourObject[`is${day}`]) {
        if (!startDay) startDay = day;
        endDay = day;
      } else {
        closedDays.push(day);
      }
    }

    // Replace misspelled days with correct spellings
    startDay = getCorrectDay(startDay);
    endDay = getCorrectDay(endDay);

    // Construct opening hours string
    let openingHours = "";
    if (startDay && endDay) {
      openingHours = startDay === endDay ? startDay : `${startDay} - ${endDay}`;
    } else if (startDay) {
      openingHours = startDay;
    }

    let closedDaysString = "";
    if (closedDays.length > 0) {
      closedDaysString = closedDays.map(day => getCorrectDay(day)).join(", ");
    }

    return { openingHours, closedDays: closedDaysString };
  };

  const { openingHours, closedDays } = getOpeningHours(props.businessHourObject);


  const renderContent = () => {
    switch (activeTab) {
      case "cab":
        return <div> <CabsTab /> </div>;
      case "avilablecab":
        return <div><AvilableCabTab  cabs={props?.cabArray} /> </div>;
      case "about":
        return <div><CabAbout desc={props?.description||""} /></div>;
      case "termcondition":
          return <div><TermAndCondition termsConditions={props?.termsConditionData} /></div>;
      case "contact":
            return <div><CabContactInfo contdata={props?.contactInfo} socialdata={props?.socialMediaData}/></div>;
      default:
        return null;
    }
  };

  const getTabClass = (tabName) => {
    return activeTab === tabName
      ? "flex bg-Cabdefaultcolor px-3 p-2 border-2 border-none items-center rounded mx-2 cursor-pointer"
      : "flex bg-transparent p-2 px-3 border-1 border-Bordergray items-center rounded mx-2 cursor-pointer";
  };

  return (
    <div>
      <div class="main-temaplt" id="temp22">
        <div className="container">
          <div className="row justify-content-center">
          <div style={{ width: "420px" }} className="col-12 wtemplate">
             {/* ///////////////////////////   cab Template     /////////////////////// */}

            <div>
              <div className="relative bannerimg22">
                <img className="w-full object-fill" src={props?.bannerImageUrl
                      ? props.getImage(props?.bannerImageUrl)
                      : CabBanner}/>
                <div className="flex  absolute items-center text-center" style={{top:"50px", flexDirection:"column", lineHeight:"10px"}}>
                  <img className="w-fiftypx pb-2" src={props?.logoImageUrl
                      ? props.getImage(props?.logoImageUrl)
                      : Cablogo} />
                  <div className="font-DM text-2xl text-white uppercase break-all"> {props?.title}</div>
                </div>
              </div>
          <div className="display-flex overflow-scroll" style={{background: "black"}} >
            <div className="flex bg-black pb-4 p-2" style={{width:"max-content"}}>
                <div 
                // className="flex bg-Cabdefaultcolor p-2 border-2 border-none items-center rounded mx-2 cursor-pointer"
                className={getTabClass("cab")}
                onClick={()=>setActiveTab("cab")}>
                  <img src={CabIcon} className="h-twelve"/>
                  <div className="px-1 font-Poppins font-medium text-white">Cab</div>
                </div>
                {props?.onState?.cabproduct && (
                 <div 
                //  className="flex bg-transparent p-2 border-1 border-Bordergray items-center rounded mx-2 cursor-pointer"
                   className={getTabClass("avilablecab")}
                   onClick={()=>setActiveTab("avilablecab")}>
                  <img src={AvilableCab} className=" h-twelve"/>
                  <div className="px-1 font-Poppins font-medium text-white">Available Cabs</div>
                </div>
                )}

                <div
                //  className="flex bg-transparent p-2 border-1 border-Bordergray items-center rounded mx-2 cursor-pointer"
                className={getTabClass("about")}
                    onClick={()=>setActiveTab("about")}>
                  <img src={AboutIcon} className=" h-twelve"/>
                  <div className="px-1 font-Poppins font-medium text-white">About</div>
                </div>
                {props?.onState?.termsconditions && (
                <div 
                // className="flex bg-transparent p-2 border-1 border-Bordergray items-center rounded mx-2 cursor-pointer"
                className={getTabClass("termcondition")}
                    onClick={()=>setActiveTab("termcondition")}>
                  <img src={COntactIcon} className="h-twelve"/>
                  <div className="px-1 font-Poppins font-medium text-white">Term & Conditions</div>
                </div> 
                )}
                {props?.onState?.contactInfo || props?.onState?.socials && (
                <div
                //  className="flex bg-transparent p-2 border-1 border-Bordergray items-center rounded mx-2 cursor-pointer"
                className={getTabClass("contact")}
                    onClick={()=>setActiveTab("contact")}>
                  <img src={COntactIcon} className="h-twelve"/>
                  <div className="px-1 font-Poppins font-medium text-white">Contact</div>
                </div> 
            )}
            </div>
          </div>
          <div className="px-2 bg-black" style={{height: "calc(100vh - 262px)", overflowY: "scroll"}}>{renderContent()}</div>
            </div>
             
       



             {/* ///////////////////////////   cab Template     /////////////////////// */}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Theme22;
