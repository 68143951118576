import React, { useContext, useEffect, useState, useRef } from "react";
import PageName from "../Utils/PageName.js";
import { Link } from "react-router-dom";
import { environmentVariables } from "../../config/env.config.js";
import axios from "axios";
import Swal from "sweetalert2";
import CircularLoader from "../Components/CircularLoader/CircularLoader.js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { load } from "@cashfreepayments/cashfree-js";
import { currencyContext } from "../../context/currencyContext.js";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import styled from "styled-components";
import { userContext } from "../../context/userContext";
import "./../Pages/Updatedcss/UpdatedPlanStyle.css";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from "../../utils/utility.js";

function Plans() {
  const [dataArr, setDataArr] = useState([]);
  const [contactSalesPlanType, setContactSalesPlanType] = useState("");
  const [dataArrYear, setDataArrYear] = useState([]);
  const [dataArrMonth, setDataArrMonth] = useState([]);
  const [dataArrLifetime, setDataArrLifetime] = useState([]);
  const { userData, setUserData } = useContext(userContext);
  const [activeUserPlan, setActiveUserPlan] = useState({});
  const [cashfree, setCashfree] = useState(null);
  const [loading, setLoading] = useState(true);
  const baseUrl = environmentVariables?.apiUrl;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString();
  const [price, setPrice] = useState("");
  const selectRef = useRef(null);
  const [maxUsers, setMaxUsers] = useState("");
  const [maxBusiness, setMaxBusiness] = useState("");
  const [numberOfpvc, setnumberOfpvc] = useState("");
  const [numberOfMetal, setnumberOfMetal] = useState("");
  const [storageLimit, setStorageLimit] = useState("");
  const [enableDomain, setEnableDomain] = useState(false);
  const [enableSubDomain, setenableSubDomain] = useState(false);
  const [enableBranding, setenableBranding] = useState(false);
  const [progressiveWebApp, setprogressiveWebApp] = useState(false);
  const [enableQRCode, setenableQRCode] = useState(false);
  const [enableChatgpt, setenableChatgpt] = useState(false);
  const [enablePVCCard, setenablePVCCard] = useState(false);
  const [enableMetalCard, setenableMetalCard] = useState(false);
  const [textareaValue, setTextareaValue] = useState("");
  const [invalidPhoneErr, setInvalidPhoneErr] = useState("");
  const user_type = userData?.user_type;
  const [paymentMode, setPaymentMode] = useState("-1");
  const [isPaymentBtnClicked, setIsPaymentBtnClicked] = useState(false);
  const navigate = useNavigate();
  const { currencyObjData, setCurrencyObjData } = useContext(currencyContext);
  const [countryCode, setCountryCode] = useState(userData.country_code);
  const [currencyCode, setCurrencyCode] = useState();

  useEffect(()=>{
    setCurrencyCode(currencyObjData?.name)
  },[])

  let initializeSDK = async function () {
    let cashfree1 = await load({
      mode: "sandbox",
    });
    setCashfree(cashfree1);
  };
  useEffect(() => {
    initializeSDK();
  }, []);

  const CenteredTabList = styled.div`
    display: flex;
    justify-content: center;
  `;
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const selectedValue = selectRef.current.value;
      const response = await axios.post(
        `${baseUrl}api/v1/plan/createPlan`,
        {
          name,
          price,
          duration: selectedValue,
          max_users: maxUsers,
          business: maxBusiness,
          storage_limit: storageLimit,
          enable_custdomain: enableDomain ? "on" : "off",
          enable_custsubdomain: enableSubDomain ? "on" : "off",
          enable_branding: enableBranding ? "on" : "off",
          pwa_business: progressiveWebApp ? "on" : "off",
          enable_qr_code: enableQRCode ? "on" : "off",
          enable_chatgpt: enableChatgpt ? "on" : "off",
          enable_physical_pvc: enablePVCCard ? "on" : "off",
          enable_physical_metal: enableMetalCard ? "on" : "off",
          description: textareaValue,
          no_of_pvc_card: numberOfpvc,
          no_of_metal_card: numberOfMetal,
          themes: "Theme1, Theme2",
        },
        {
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        }
      );
      if (response?.data?.success) {
        Swal.fire("Plan saved");
        setTimeout(() => {
          Swal(null);
        }, 3000);

        window.location.reload();
      }
    } catch (error) {
      Swal.fire(error?.response?.data?.message);
      console.error("Error:", error?.response?.data?.message);
    }
  };

  const [data, setData] = useState({});
  const [editedData, setEditedData] = useState({});
  const [planId, setPlanId] = useState("");
  const [tempKeyForLoader, setTempKeyForLoader] = useState("");
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [contactLastName, setContactLastName] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitContactSales, setIsSubmitContactSales] = useState(false);
  const handleSubmitContactSales = () => {
    if (firstName == "") {
      toast.error("Firstname is required!");
      return;
    } else if (contactLastName == "") {
      toast.error("Lastname is required!");
      return;
    } else if (contactEmail == "") {
      toast.error("Email is required!");
      return;
    } else if (mobile == "") {
      toast.error("Mobile Number is required!");
      return;
    }else if (invalidPhoneErr !== "") {
      toast.error("Please enter a valid mobile number!");
      return;
    }else if (message == "") {
      toast.error("Message is required!");
      return;
    }
    setIsSubmitContactSales(true);
    let data = {
      firstname: firstName,
      lastname: contactLastName,
      email: contactEmail,
      mobile: mobile,
      message: message,
      plantype: contactSalesPlanType,
    };
    let config = {
      method: "post",
      url: `${baseUrl}api/v1/user/UserEnterprise`,
      headers: {
        _token: userData?.token,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        toast.success(
          "Thanks for contacting, someone from our team will get back to you soon!"
        );
        setFirstName('')
        setContactEmail('')
        setMobile("")
        setContactLastName('')
        setMessage('')
        setIsSubmitContactSales(false);
      })
      
      .catch((err) => {
        toast.error(err?.response?.data?.message || err?.message);
    setIsSubmitContactSales(false);

      });
  };
  const handleTabSelect = (index) => {
    setSelectedTabIndex(index);
  };

  const fetchData = async (id) => {
    try {
      setPlanId(id);
      axios
        .get(`${baseUrl}api/v1/plan/fetchPlanById`, {
          params: { planId: id },
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        })
        .then((response) => {
          setData(response.data);
          setEditedData(response?.data?.result);
        })
        .catch((error) => {
          console.error("Error:", error?.response?.data?.message);
        });
    } catch (error) {
      console.error("API Error:", error?.response?.data?.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData({ ...editedData, [name]: value });
  };
  useEffect(() => {}, [editedData]);

  const handleSave = async () => {
    try {
      let data = JSON.stringify(editedData);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseUrl}api/v1/plan/editPlan?planId=${planId}`,
        headers: {
          _token: userData?.token,
          "Content-Type": "application/json",
        },
        withCredentials: true,
        data: data,
      };

      axios
        .request(config)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const fetchPlanData = async () => {
    const encodedCountryCode = encodeURIComponent(userData.country_code);
    let apiEnd = "";
    if (user_type === "super_admin") {
      apiEnd = "/fatchPlans";
    } else {
      apiEnd = `/fatchPlansUsers_withcountry?country=${encodedCountryCode}`;
    }

    try {
      setLoading(true);
     await axios
        .get(`${baseUrl}api/v1/plan${apiEnd}`, {
          headers: {
            _token: userData?.token,
          },
        })
        .then((response) => {
        
          const filteredDataYear =
            response.data?.result.filter(
              (item) => item.duration === "Per Year" 
            ) || [];
          const filteredDataMonth =
            response.data?.result.filter(
              (item) => item.duration === "Per Month"
            ) || [];
            const filteredDataLifetime =
            response.data?.result.filter(
              (item) => item.duration === "Lifetime"
            ) || [];
          setDataArr(response.data?.result);
          setDataArrYear(filteredDataYear);
          setDataArrMonth(filteredDataMonth);
          setDataArrLifetime(filteredDataLifetime);
          setLoading(false);
        })     
    } catch (error) {
      console.error("API Error:", error?.response?.data?.message);
      setLoading(false);
    }
  };

  const fetchSpecificUserActivePlan = () => {
    let apiEnd = "";
    // console.log("userrrrrrrrrrrrr");

    try {
      axios
        .get(`${baseUrl}api/v1/plan/fetchSpecificUserActivePlan`, {
          headers: {
            _token: userData?.token,
          },
        })
        .then((response) => {
          // console.log(response?.data?.findPlanArr[0], "ArrMonth responseresponse user");
          if (response?.data?.findPlanArr && response?.data?.findPlanArr[0]) {
            setUserData({
              ...userData,
              plan: response?.data?.findPlanArr[0]?.plan_id,
              findPlanArr: [
                {
                  buy_date: response?.data?.findPlanArr[0]?.buy_date,
                  expiry_date: response?.data?.findPlanArr[0]?.expiry_date,
                  plan_id: response?.data?.findPlanArr[0]?.plan_id,
                  start_date: response?.data?.findPlanArr[0]?.start_date,
                  user_id: response?.data?.findPlanArr[0]?.user_id,
                  price: response?.data?.findPlanArr[0]?.price,
                },
              ],
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error?.response?.data?.message);
        });
    } catch (error) {
      console.error("API Error:", error?.response?.data?.message);
    }
  };
  const fetchActivePlan = () => {
    // dataArrYear
    // dataArrMonth
    // userData
    // activeUserPlan,
    if (dataArrYear?.length || dataArrMonth?.length) {
      let activePlan = dataArrMonth?.find(
        (el) => el?.id == userData?.findPlanArr[0]?.plan_id
      );
      if (activePlan && activePlan?.id) {
        setActiveUserPlan(activePlan);
      } else {
        let activePlan = dataArrMonth?.find(
          (el) => el?.id == userData?.findPlanArr[0]?.plan_id
        );
        setActiveUserPlan(activePlan);
      }
    }
  };
  useEffect(() => {
    fetchPlanData();
    fetchSpecificUserActivePlan();
    return ()=>{
       setDataArr([]);
       setDataArrYear([]);
       setDataArrMonth([]);
       setDataArrLifetime([]);
    }
  }, []);
  useEffect(() => {
    fetchActivePlan();
    return ()=>{
      setActiveUserPlan({})
    }
  }, [dataArrYear, dataArrMonth]);

  const handleMonthlySubscribe = async (planId, planPrice) => {
    try {
      setTempKeyForLoader(planId);
      setIsPaymentBtnClicked(true);
      let formDataObject = {
        plan_id: planId,
        currency: currencyObjData?.shortform,
      };
      const config = {
        headers: {
          _token: userData?.token,
          "Content-Type": "application/json",
        },
      };

      const response = await axios.post(
        `${baseUrl}api/v1/pg/StripeCreateCheckoutSession`,
        formDataObject,
        config
      );
      if (response.data.status === "success") {
        const checkoutUrl = response.data.data.url;
        // console.log(checkoutUrl, "checkoutUrl!@!@@#");
        window.location.href = checkoutUrl;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleProceedNext = (planId, planPrice) => {
    let cardAmountAfterDiscount = planPrice;
    setIsPaymentBtnClicked(true);
    setTempKeyForLoader(planId);
    const currencySymbol = currencyObjData?.shortform;
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/user/create-payment-intent-ForPlan`,
      data: {
        amount: parseInt(
          cardAmountAfterDiscount * 100
        ),
        currency: currencySymbol,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response?.data?.clientSecret) {
          navigate("/payment-plan", {
            state: {
              data: response?.data?.clientSecret,
              amount: cardAmountAfterDiscount,
              // cardId: cardId,
              planId: planId,
            },
          });
          setIsPaymentBtnClicked(false);
          setTempKeyForLoader("");
        }
      })
      .catch((err) => {
        setIsPaymentBtnClicked(false);
        setTempKeyForLoader("");
        console.log(err, "Erroroorro");
        navigate("/plans");
      });
  };

  const handleProceedNextDPO =async(planId, planPrice) => {
    try{
    // console.log(planId,planPrice,currencyObjData?.name,"handleProcee222222222dNextDPO")
    // return
    let cardAmountAfterDiscount = planPrice;
    setIsPaymentBtnClicked(true);
    setTempKeyForLoader(planId);
    const currencySymbol = currencyObjData?.shortform;
    const response = await fetch(`${environmentVariables?.apiUrl}api/v1/payment/getTransTokenPlan`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        // Provide any necessary data for the transaction
        amount: planPrice,
        currency: currencyObjData?.name,
        planId:planId
      }),
    });

    const data = await response.json();

    // Handle the response from the backend
    if (response.ok) {
      // Payment initiated successfully
      console.log('Payment initiated:', data);
      if (data.success && data.result && data.result.API3G && data.result.API3G.TransToken && data.result.API3G.TransRef) {
        // Redirect to the payment URL with the received token
        window.location.href = `https://secure.3gdirectpay.com/payv3.php?ID=${data.result.API3G.TransToken._text}`;
      setIsPaymentBtnClicked(false);
      } else {
        console.error('Missing data for redirect');
      setIsPaymentBtnClicked(false);
      }
    } else {
      // Payment initiation failed
      console.error('Error initiating payment:', data.message);
      setIsPaymentBtnClicked(false);
    }
  } catch (error) {
    console.error('Error initiating payment:', error);
    setIsPaymentBtnClicked(false);
  }
}

//for razorpay
const loadRazorpayScript = () => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const handleProceedPaymentGatewayIndia=async (planId,planPrice)=>{
  axios.get(`${environmentVariables?.apiUrl}api/v1/pg/method`).then(response=>{
    if(response?.data?.success==true){
      if(response?.data?.data=='razorpay'){
        handleProceedNextRazorPay(planId,planPrice)

      }else if(response?.data?.data=='cashfree'){
        handleProceedCashfree(planId,planPrice);

      }else{
        handleProceedCashfree(planId,planPrice);

      }
    }else{
      toast.error(response?.data?.message)
    }
  }).catch(err=>{
    console.log(err);
    toast.error(err?.response?.data?.message||err?.message)
  })
}


const handleProceedNextRazorPay=async(planId,planPrice)=>{
   try{
    let cardAmountAfterDiscount = planPrice;
   setIsPaymentBtnClicked(true);
   setTempKeyForLoader(planId);
    const resRazorPay = await loadRazorpayScript();
    if (!resRazorPay) {
      toast.error("Razorpay SDK failed to load, Are you Online?");
      setIsPaymentBtnClicked(false);
    }
    const data = {
      price: planPrice,
      price_currency: currencyObjData?.name,
      plan_id: planId,
      plan_name: "NA",
      order_id: Date.now(),
      card_number: "999",
      card_exp_month: "22",
      txn_id: "NA",
      payment_status: "pending",
      receipt: "NA",
      store_id: 1,
      payment_type: "Razor Pay Payment",
      payment_data: "",
      name:
        userData?.created_by != 0 ? userData?.parentUser?.name : userData?.name,
      email:
        userData?.created_by != 0
          ? userData?.parentUser?.email
          : userData?.email,
      user_id: userData?.created_by != 0 ? userData?.created_by : userData?.id,
    };
    const config = {
      method: "post",
      url: `${environmentVariables?.apiUrl}api/v1/pg/razorpay/createOrderPlan`,
      withCredentials: true,
      headers: {
        _token: userData?.token,
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        const options = {
          key: process.env.RAZORPAY_ID, // Enter the Key ID generated from the Dashboard
          amount: parseFloat(
            Number(response?.data?.data?.amount) / 100
          ).toFixed(2),
          currency: response?.data?.data?.currency,
          name: "Businessbay",
          description: "Your Transaction",
          order_id: response?.data?.data?.id,
          handler: (response) => {
            window.location.href = `/paymentPlans?status=success&txn_id=${response.razorpay_payment_id}&orderid=${response?.razorpay_order_id}&plan_id=${planId}&price=${planPrice}&currency=${response?.data?.data?.currency}`;
            // Optionally, handle the response here (e.g., store the payment info in your backend)
          },
          theme: {
            color: "#3399cc",
          },
          modal: {
            ondismiss: () => {
              console.log("failure");

              toast.error("Payment Failed");

              // Redirect to failure page
              // window.location.href = "/redirect?status=failure";
            },
          },
        };
        const rzp = new window.Razorpay(options);
        rzp.on("payment.failed", function (response) {
          setIsPaymentBtnClicked(false);
          
          window.location.href = `/paymentPlans?status=failure&payment_id=${response.error.metadata.payment_id}&orderid=${response?.error?.metadata?.order_id}`;
        });
        rzp.open();
        setIsPaymentBtnClicked(false);
      })
      .catch((error) => {
        console.log(error);
        setIsPaymentBtnClicked(false);
        toast.error(error?.response?.data?.message || error?.message);
      });
   }catch(error){
     console.error("Error initiating payment:", error);
     setIsPaymentBtnClicked(false);
   }
}

const handleProceedCashfree=async (planId,planPrice)=>{
    try{
      const data = {
        price: planPrice,
        price_currency: currencyObjData?.name,
        plan_id: planId,
        plan_name: "NA",
        order_id: Date.now(),
        card_number: "999",
        card_exp_month: "22",
        txn_id: "NA",
        payment_status: "pending",
        receipt: "NA",
        store_id: 1,
        payment_type: "Razor Pay Payment",
        payment_data: "",
        name:
          userData?.created_by != 0 ? userData?.parentUser?.name : userData?.name,
        email:
          userData?.created_by != 0
            ? userData?.parentUser?.email
            : userData?.email,
        user_id: userData?.created_by != 0 ? userData?.created_by : userData?.id,
      };
      const config = {
        method: "post",
        url: `${environmentVariables?.apiUrl}api/v1/pg/cashfree/createOrderPlan`,
        withCredentials: true,
        headers: {
          _token: userData?.token,
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          const doPayment = async () => {
            let checkoutOptions = {
              paymentSessionId: response?.data?.data?.payment_session_id,
              redirectTarget: "_self",
            };
            cashfree.checkout(checkoutOptions);
          };
          doPayment();
          setIsPaymentBtnClicked(false);
        })
        .catch((error) => {
          console.log(error);
          setIsPaymentBtnClicked(false);
          toast.error(error?.response?.data?.message || error?.message);
        });
    }catch(error){
      console.error("Error initiating payment:", error);
      setIsPaymentBtnClicked(false);
    }
}

  const handleContactSales = (planType) => {
    if (window !== undefined) {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth", // You can use 'auto' or 'smooth' for smooth scrolling
      });
    }
    setContactSalesPlanType(planType);
  };

  const [planObj, setPlanObj] = useState({});
  const getPlanName = () => {
    axios
      .get(`${baseUrl}api/v1/plan/fetchPlanByIdForUser`, {
        params: { planId: userData?.plan },
        headers: {
          _token: userData?.token,
        },
        // withCredentials: true,
      })
      .then((res) => {
        setPlanObj(res?.data?.result);
      })
      .catch((err) => {
        console.log({ err: err });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // useEffect(() => {
  //   console.log(userData, "userData@ ArrMonth #@$#");
  // }, [userData]);
  useEffect(() => {
    getPlanName();
    return ()=>{
      setPlanObj({})
    }
  }, [userData]);

  const handleBlur = (value, phoneData) => {
    phoneData.iso2 = phoneData.countryCode;
    let contactNumber = value.target.value;
    if(!!contactNumber){
      let val = contactNumber.split(" ");
      let valArray = (val.length>0)?val[1]:"";
      let valueData = (valArray)?valArray.replace("-",""):"";
      if(valueData==''){
        setInvalidPhoneErr(`Please enter phone number.`);
      }else if (!isValidPhoneNumber(valueData, phoneData)) {
        setInvalidPhoneErr(`Invalid phone number for ${phoneData.name}`);
      } else {
        setInvalidPhoneErr('');
      }
    }
  };


  return (
    <>
      <PageName PageName="Plans" />
      {loading ? (
        <CircularLoader size={40} />
      ) : (
        <>
      <CenteredTabList>
        <Tabs selectedIndex={selectedTabIndex} onSelect={handleTabSelect}>
          

          <TabPanel>
            <>
              <section id="price" className="ptable">
                <div className="demo">
                  <div className="container">
                    <div className="col-12 col-lg-12">
                      <div className="tile-a">
                        <div className="tile-title-w-btn mb-0">
                          {user_type === "super_admin" && (
                            <p>
                              <button
                                className="btn btn-primary icon-btn"
                                data-bs-toggle="modal"
                                data-bs-target="#planselect"
                              >
                                Add Item{" "}
                              </button>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row Mobileplangrid">
                      {/* Monthly */}
                      {dataArrMonth?.map((item, index) => (
                        <div className="col-12 col-md-4 col-sm-6  plan_Wrapper">
                          <div
                            className="pricingTable green planpadissueres"
                            id="planwrapper_height"
                          >
                            <div className="pricingTable-header">
                              <h3 className="title">
                                {/* {dataArrMonth[index]?.name} / Monthly */}
                                {dataArrMonth[index]?.name} / {dataArrMonth[index]?.id === 1 ? "15 days" : "Monthly"}
                              </h3>
                            </div>
                            {dataArrMonth[index]?.name != "Free Plan" ? (
                              <div className={"price-value"}>
                                {/* {`${currencyObjData?.symbol}
                                ${(
                                  dataArrMonth[index]?.price *
                                  currencyObjData?.current_value
                                ).toFixed(2)}`} */}
                                {`${currencyObjData?.symbol}
                                ${( dataArrMonth[index]?.price).toFixed(2)}`}
                              </div>
                            ) : (
                              <div className="price-value">
                                {currencyObjData?.symbol} {(0).toFixed(2)}
                              </div>
                            )}

                            {planObj?.is_enterprise == true ? (
                              <></>
                            ) : (
                              <span className="L729">
                                {userData?.findPlanArr.length > 0 ? (
                                  <>
                                  {console.log(userData?.findPlanArr,"qwd")
                                  }
                                    {userData?.findPlanArr.length > 0 &&
                                      userData?.findPlanArr?.some(
                                        (el) => el?.plan_id === item?.id
                                      ) ? (
                                        <div className="subscribed">
                                          Subscribed
                                        </div>
                                      ):""}
                                      
                                    {/* {item?.id == userData?.plan && ( */}
                                    {userData?.findPlanArr?.some(
                                      (el) => el?.plan_id == item?.id
                                    ) && (
                                      <div className="desline">
                                        Plan expires : 
                                        {userData?.findPlanArr
                                          ? (() => {
                                              const filteredPlans =
                                                userData?.findPlanArr?.filter(
                                                  (el) =>
                                                    el?.plan_id ===
                                                    userData?.plan
                                                );
                                              const foundPlan =
                                                filteredPlans.length > 0
                                                  ? filteredPlans[0]
                                                  : null;
                                              return foundPlan
                                                ? new Date(
                                                    foundPlan?.expiry_date
                                                  )?.toDateString()
                                                : "N/A";
                                            })()
                                          : "N/A"}
                                      </div>
                                    )}
                                    
                                    {activeUserPlan?.duration == "Per Year" ? (
                                      isPaymentBtnClicked &&
                                      tempKeyForLoader ==
                                      dataArrMonth[index]?.id ? (
                                        <CircularLoader size={25} />
                                      ) : (
                                        item.name != "Free Plan" && (
                                         <>
                                          {countryCode === '+91' && (
                                            <button
                                              className="upgrade_plan"
                                              // style={{display:"none"}}
                                              onClick={() => {
                                                if (item?.name === "Enterprise Plan") {
                                                  handleContactSales("year");
                                                } else {
                                                  handleProceedPaymentGatewayIndia(
                                                    dataArrMonth[index]?.id,
                                                    dataArrMonth[index]?.price
                                                  );
                                                }
                                              }}
                                            >
                                              Upgrade
                                            </button>
                                          )}
                                          {countryCode !== '+91' && userData?.findPlanArr.length > 0 &&
                                      !userData?.findPlanArr?.some(
                                        (el) => el?.plan_id === item?.id
                                      ) && (
                                            <button
                                              className="upgrade_plan"
                                              onClick={() => {
                                                if (item?.name === "Enterprise Plan") {
                                                  handleContactSales("year");
                                                } else {
                                                  handleProceedNextDPO(
                                                    dataArrMonth[index]?.id,
                                                    dataArrMonth[index]?.price
                                                  );
                                                }
                                              }}
                                            >
                                              Upgrade
                                            </button>
                                          )}
                                         </>
                                        )
                                      )
                                    ) : (
                                      item?.price >
                                        userData?.findPlanArr[0]?.price ?
                                      (isPaymentBtnClicked &&
                                      tempKeyForLoader ==
                                      dataArrMonth[index]?.id ? (
                                        <CircularLoader size={25} />
                                      ) : (
                                        item.name != "Free Plan" && (
                                          <>
                                          {countryCode === '+91' && (
                                            <button
                                              className="upgrade_plan"
                                              onClick={() => {
                                                if (item?.name === "Enterprise Plan") {
                                                  handleContactSales("year");
                                                } else {
                                                  handleProceedPaymentGatewayIndia(
                                                    dataArrMonth[index]?.id,
                                                    dataArrMonth[index]?.price
                                                  );
                                                }
                                              }}
                                            >
                                              Upgrade
                                            </button>
                                          )}

                                          {countryCode !== '+91' && (
                                            <button
                                              className="upgrade_plan"
                                              onClick={() => {
                                                if (item?.name === "Enterprise Plan") {
                                                  handleContactSales("year");
                                                } else {
                                                  handleProceedNextDPO(
                                                    dataArrMonth[index]?.id,
                                                    dataArrMonth[index]?.price
                                                  );
                                                }
                                              }}
                                            >
                                              Upgrade
                                            </button> 
                                          )}
                                         </>
                                        )
                                      )):""
                                    )}
                                  </>
                                ) : (
                                  item.name != "Free Plan" &&
                                  (isPaymentBtnClicked &&
                                  tempKeyForLoader ==
                                    dataArrMonth[index]?.id ? (
                                    <CircularLoader size={25} />
                                  ) : (
                                    <>
                                      {(userData?.country_code == "+91")?
                                      <button
                                        className="upgrade_plan"
                                        onClick={() => {
                                          if (item?.name == "Enterprise Plan") {
                                            handleContactSales("month");
                                          } else {
                                            handleProceedPaymentGatewayIndia(
                                              dataArrMonth[index]?.id,
                                              dataArrMonth[index]?.price
                                            );
                                          }
                                        }}
                                      >
                                        Upgrade
                                      </button>
                                      :
                                      <button
                                            className="upgrade_plan"
                                            onClick={() => {
                                              if (
                                                item?.name == "Enterprise Plan"
                                              ) {
                                                handleContactSales("month");
                                              } else {
                                                handleProceedNextDPO(
                                                  dataArrMonth[index]?.id,
                                                  dataArrMonth[index]?.price
                                                );
                                              }
                                            }}
                                          >
                                            {"Upgrade"}
                                          </button>
                                        }
                                    </>
                                  ))
                                )}
                              </span>
                            )}

                            <ul className="pricing-content planmarissueres">
                              <li className="on">
                                {dataArrMonth[index]?.themes}
                                Theme
                              </li>
                              <li className="on">
                                {`${dataArrMonth[index]?.business} Business`}
                              </li>
                              <li className="on">
                                {`${dataArrMonth[index]?.appoinments} Appointments`}
                              </li>
                              <li className="on">
                                {`${dataArrMonth[index]?.contacts} Contact`}
                              </li>
                              <li className="on">
                                {`${dataArrMonth[index]?.max_users} Users`}
                              </li>
                              <li className="on">
                                {dataArrMonth[index]?.name == "Enterprise Plan"}
                              </li>
                              <li
                                className={
                                  dataArrMonth[index]?.enable_custdomain ===
                                  "on"
                                    ? "on d-none"
                                    : "disable d-none"
                                }
                              >
                                Custom Domain
                              </li>

                              <li
                                className={
                                  dataArrMonth[index]?.enable_custsubdomain ===
                                  "on"
                                    ? "on d-none"
                                    : "disable d-none"
                                }
                              >
                                {" "}
                                Sub Domain
                              </li>
                              <li className="on">
                                {`${dataArrMonth[index]?.business} SubDomain`}
                              </li>
                              <li className="on">
                                {`${dataArrMonth[index]?.business} PWA`}
                              </li>

                              <li
                                className={
                                  dataArrMonth[index]?.enable_qr_code === "on"
                                    ? "on"
                                    : "disable"
                                }
                              >
                                {"QR Code"}
                              </li>

                              <li
                                className={
                                  dataArrMonth[index]?.enable_physical_pvc ===
                                  "on"
                                    ? "on"
                                    : "disable"
                                }
                              >
                                {`${dataArrMonth[index]?.no_of_pvc_card} PVC Card`}
                              </li>

                              <li
                                className={
                                  dataArrMonth[index]?.enable_physical_metal ===
                                  "on"
                                    ? "on"
                                    : "disable"
                                }
                              >
                                {`${dataArrMonth[index]?.no_of_metal_card} Metal
                                Card`}
                              </li>
                              <li
                                  className={
                                    dataArrMonth[index]
                                      ?.Applications === "on"
                                      ? "on"
                                      : "disable"
                                  }
                                >
                                {`Applications (Android, ios, mac, windows)`}
                                </li>
                                <li className={
                                    dataArrMonth[index]
                                      ?.Notifi === "on"
                                      ? "on"
                                      : "disable"
                                  }>
                                Notifications-{dataArrMonth[index]?.Notifications || 0}/Month
                                </li>
                                <li className="on"> Chat and email support
                                </li>
                              <li>
                                {`${dataArrMonth[index]?.storage_limit} MB Storage
                                Limit`}
                              </li>
                              <li
                                  className={
                                    dataArrMonth[index]
                                      ?.Marketplace_Listing === "on"
                                      ? "on"
                                      : "disable"
                                  }
                                >
                                {`Marketplace Listing`}
                                </li>
                                <li
                                className={
                                  dataArrMonth[index]
                                    ?.MKTLeads === "on"
                                    ? "on"
                                    : "disable"
                                }>
                                {`${dataArrMonth[index]?.Leads || 0} Marketplace Leads every month`}
                              </li>
                              <li
                              className={
                                dataArrMonth[index]
                                  ?.agm === "on"
                                  ? "on"
                                  : "disable"
                              }>
                                {`SEO- AGM- ${dataArrMonth[index]?.SEO || 0}`}
                              </li>
                              <li
                                  className={
                                    dataArrMonth[index]
                                      ?.RM_Support === "on"
                                      ? "on"
                                      : "disable"
                                  }
                                >
                                {`Dedicated RM Support`}
                                </li>
                                <li>
                                {`${dataArrMonth[index]?.MiniMarket || "MiniMarket"}`}
                              </li>
                              <li
                                  className={
                                    dataArrMonth[index]
                                      ?.ui === "on"
                                      ? "on"
                                      : "disable"
                                  }
                                >
                                {`Premium UI`}
                                </li>
                                <li 
                                className={
                                  dataArrMonth[index]
                                    ?.DedLeads === "on"
                                    ? "on"
                                    : "disable"
                                }>
                                {`Dedicated Leads- ${dataArrMonth[index]?.DLeads || 0}`}
                              </li>
                              <li
                                  className={
                                    dataArrMonth[index]
                                      ?.Google_Review === "on"
                                      ? "on"
                                      : "disable"
                                  }
                                >
                                {`Google Review Card`}
                                </li>
                                <li
                                  className={
                                    dataArrMonth[index]
                                      ?.Whatsapp === "on"
                                      ? "on"
                                      : "disable"
                                  }
                                >
                                {`Whats app integration`}
                                </li>
                                <li
                                  className={
                                    dataArrMonth[index]
                                      ?.Marketing_Consultation === "on"
                                      ? "on"
                                      : "disable"
                                  }
                                >
                                {`Marketing Consultation`}
                                </li>
                                <li
                                  className={
                                    dataArrMonth[index]
                                      ?.Playstore === "on"
                                      ? "on"
                                      : "disable"
                                  }
                                >
                                {`Application submission on playstore and app store: This is liable on the playstore and appstore to accept your application`}
                                </li>
                            </ul>
                          </div>
                        </div>
                      ))}

                      {/* Yearly */}
                    {dataArrYear?.map((item, index) => (
                        <div className="col-12 col-md-4 col-sm-6 plan_Wrapper">
                          <div
                            className="pricingTable green"
                            id="planwrapper_height"
                          >
                            <div className="pricingTable-header">
                              <h3 className="title">
                                {dataArrYear[index]?.name} / Yearly
                              </h3>
                            </div>
                            
                            {/* price Section */}
                            {dataArrYear[index]?.name != "Free Plan" ? (
                              <div className={"price-value"}>
                                {`${currencyObjData?.symbol}
                                ${(
                                  dataArrYear[index]?.price 
                                ).toFixed(2)}`}
                              </div>
                            ) : (
                              <div className="price-value">
                                {currencyObjData?.symbol} {(0).toFixed(2)}
                              </div>
                            )}

                           

                            {planObj?.is_enterprise == true ? (
                              <></>
                            ) : (
                              <span className="L729">
                                {userData?.findPlanArr.length > 0 ? (
                                  <>
                                    {userData?.findPlanArr.length > 0 &&
                                      userData?.findPlanArr?.some(
                                        (el) => el?.plan_id === item?.id
                                      ) && (
                                        <div className="subscribed">
                                          Subscribed
                                        </div>
                                      )}

                                    {/* {item?.id == userData?.plan && ( */}
                                    {userData?.findPlanArr?.some(
                                      (el) => el?.plan_id == item?.id
                                    ) && (
                                      <div className="desline">
                                        Plan expires : 
                                        {userData?.findPlanArr
                                          ? (() => {
                                              const filteredPlans =
                                                userData?.findPlanArr?.filter(
                                                  (el) =>
                                                    el?.plan_id ===
                                                    userData?.plan
                                                );
                                              const foundPlan =
                                                filteredPlans.length > 0
                                                  ? filteredPlans[0]
                                                  : null;
                                              return foundPlan
                                                ? new Date(
                                                    foundPlan?.expiry_date
                                                  )?.toDateString()
                                                : "N/A";
                                            })()
                                          : "N/A"}
                                      </div>
                                    )}
                                    {activeUserPlan?.duration == "Per Month" ? (
                                      isPaymentBtnClicked &&
                                      tempKeyForLoader ==
                                        dataArrYear[index]?.id ? (
                                        <CircularLoader size={25} />
                                      ) : (
                                        item.name != "Free Plan" && (
                                         <>
                                          {countryCode === '+91' && (
                                            <button
                                              className="upgrade_plan"
                                              onClick={() => {
                                                if (item?.name === "Enterprise Plan") {
                                                  handleContactSales("year");
                                                } else {
                                                  handleProceedPaymentGatewayIndia(
                                                    dataArrYear[index]?.id,
                                                    dataArrYear[index]?.price
                                                  );
                                                }
                                              }}
                                            >
                                              Upgrade
                                            </button>
                                          )}
                                          {countryCode !== '+91' && userData?.findPlanArr.length > 0 &&
                                      !userData?.findPlanArr?.some(
                                        (el) => el?.plan_id === item?.id
                                      ) && (
                                            <button
                                              className="upgrade_plan"
                                              onClick={() => {
                                                if (item?.name === "Enterprise Plan") {
                                                  handleContactSales("year");
                                                } else {
                                                  handleProceedNextDPO(
                                                    dataArrYear[index]?.id,
                                                    dataArrYear[index]?.price
                                                  );
                                                }
                                              }}
                                            >
                                              Upgrade
                                            </button>
                                          )}
                                         </>
                                        )
                                      )
                                    ) : (
                                      item?.price >
                                        userData?.findPlanArr[0]?.price &&
                                      (isPaymentBtnClicked &&
                                      tempKeyForLoader ==
                                        dataArrYear[index]?.id ? (
                                        <CircularLoader size={25} />
                                      ) : (
                                        item.name != "Free Plan" && (
                                          <>
                                          {countryCode === '+91' && (
                                            <button
                                              className="upgrade_plan"
                                              onClick={() => {
                                                if (item?.name === "Enterprise Plan") {
                                                  handleContactSales("year");
                                                } else {
                                                  handleProceedPaymentGatewayIndia(
                                                    dataArrYear[index]?.id,
                                                    dataArrYear[index]?.price
                                                  );
                                                }
                                              }}
                                            >
                                              Upgrade
                                            </button>
                                          )}

                                          {countryCode !== '+91' && (
                                            <button
                                              className="upgrade_plan"
                                              onClick={() => {
                                                if (item?.name === "Enterprise Plan") {
                                                  handleContactSales("year");
                                                } else {
                                                  handleProceedNextDPO(
                                                    dataArrYear[index]?.id,
                                                    dataArrYear[index]?.price
                                                  );
                                                }
                                              }}
                                            >
                                              Upgrade
                                            </button> 
                                          )}
                                         </>
                                        )
                                      ))
                                    )}
                                  </>
                                ) : (
                                  item.name != "Free Plan" &&
                                  (isPaymentBtnClicked &&
                                  tempKeyForLoader == dataArrYear[index]?.id ? (
                                    <CircularLoader size={25} />
                                  ) : (
                                    <>
                                    {countryCode === '+91' && (
                                      <button
                                        className="upgrade_plan"
                                        onClick={() => {
                                          if (item?.name === "Enterprise Plan") {
                                            handleContactSales("year");
                                          } else {
                                            handleProceedPaymentGatewayIndia(
                                              dataArrYear[index]?.id,
                                              dataArrYear[index]?.price
                                            );
                                          }
                                        }}
                                      >
                                        Upgrade
                                      </button>
                                    )}

                                    {countryCode !== '+91' && (
                                      <button
                                        className="upgrade_plan"
                                        onClick={() => {
                                          if (item?.name === "Enterprise Plan") {
                                            handleContactSales("year");
                                          } else {
                                            handleProceedNextDPO(
                                              dataArrYear[index]?.id,
                                              dataArrYear[index]?.price
                                            );
                                          }
                                        }}
                                      >
                                        Upgrade
                                      </button>
                                    )}
                                   </>
                                  ))
                                )}
                              </span>
                            )}

                            <>
                              <ul className="pricing-content planmarissueres">
                                <li className="on">
                                  {dataArrYear[index]?.themes} Theme
                                </li>
                                <li className="on">
                                  {dataArrYear[index]?.business} Business
                                </li>
                                <li className="on">
                                  {dataArrYear[index]?.appoinments} Appointments
                                </li>
                                <li className="on">
                                  {dataArrYear[index]?.contacts} Contact
                                </li>
                                <li className="on">
                                  {dataArrYear[index]?.max_users} Users
                                </li>
                                <li className="on">
                                  {dataArrYear[index]?.business} SubDomain
                                </li> <li className="on">
                                  {dataArrYear[index]?.business} PWA
                                </li>
                                <li
                                  className={
                                    dataArrYear[index]?.enable_custdomain ===
                                    "on"
                                      ? "on d-none"
                                      : "disable d-none"
                                  }
                                >
                                  Custom Domain
                                </li>
                                <li
                                  className={
                                    dataArrYear[index]?.enable_custsubdomain ===
                                    "on"
                                      ? "on d-none"
                                      : "disable d-none"
                                  }
                                >
                                  {" "}
                                  Sub Domain
                                </li>

                                
                                <li
                                  className={
                                    dataArrYear[index]?.enable_qr_code === "on"
                                      ? "on"
                                      : "disable"
                                  }
                                >
                                  Customised QR
                                </li>
                                
                                <li
                                  className={
                                    dataArrYear[index]?.enable_physical_pvc ===
                                    "on"
                                      ? "on"
                                      : "disable"
                                  }
                                >
                                  {`${dataArrYear[index]?.no_of_pvc_card} PVC Card`}
                                </li>
                                <li
                                  className={
                                    dataArrYear[index]
                                      ?.enable_physical_metal === "on"
                                      ? "on"
                                      : "disable"
                                  }
                                >
                                {`${dataArrYear[index]?.no_of_metal_card} Metal Card`}
                                </li>
                                <li
                                  className={
                                    dataArrYear[index]
                                      ?.Applications === "on"
                                      ? "on"
                                      : "disable"
                                  }
                                >
                                {`Applications (Android, ios, mac, windows)`}
                                </li>
                                <li className="">
                                Notifications-{dataArrYear[index]?.Notifications || 0}/Month
                                </li>
                                <li className="on"> Chat and email support
                                </li>
                                <li className="">
                                  {dataArrYear[index]?.storage_limit} MB Storage
                                  Limit
                                </li>
                                <li
                                  className={
                                    dataArrYear[index]
                                      ?.Marketplace_Listing === "on"
                                      ? "on"
                                      : "disable"
                                  }
                                >
                                {`Marketplace Listing`}
                                </li>
                                <li  className={
                                  dataArrYear[index]
                                    ?.MKTLeads === "on"
                                    ? "on"
                                    : "disable"
                                }>
                                {`${dataArrYear[index]?.Leads || 0} Marketplace Leads every month`}
                              </li>
                              <li 
                              className={
                                dataArrYear[index]
                                  ?.agm === "on"
                                  ? "on"
                                  : "disable"
                              }>
                                {`SEO- AGM- ${dataArrYear[index]?.SEO || 0}`}
                              </li>
                              <li
                                  className={
                                    dataArrYear[index]
                                      ?.RM_Support === "on"
                                      ? "on"
                                      : "disable"
                                  }
                                >
                                {`Dedicated RM Support`}
                                </li>
                                <li>
                                {`${dataArrYear[index]?.MiniMarket || "MiniMarket"}`}
                              </li>
                              <li
                                  className={
                                    dataArrYear[index]
                                      ?.ui === "on"
                                      ? "on"
                                      : "disable"
                                  }
                                >
                                {`Premium UI`}
                                </li>
                                <li  className={
                                  dataArrYear[index]
                                    ?.DedLeads === "on"
                                    ? "on"
                                    : "disable"
                                }>
                                {`Dedicated Leads- ${dataArrYear[index]?.Leads || 0}`}
                              </li>
                              <li
                                  className={
                                    dataArrYear[index]
                                      ?.Google_Review === "on"
                                      ? "on"
                                      : "disable"
                                  }
                                >
                                {`Google Review Card`}
                                </li>
                                <li
                                  className={
                                    dataArrYear[index]
                                      ?.Whatsapp === "on"
                                      ? "on"
                                      : "disable"
                                  }
                                >
                                {`Whats app integration`}
                                </li>
                                <li
                                  className={
                                    dataArrYear[index]
                                      ?.Marketing_Consultation === "on"
                                      ? "on"
                                      : "disable"
                                  }
                                >
                                {`Marketing Consultation`}
                                </li>
                                <li
                                  className={
                                    dataArrYear[index]
                                      ?.Playstore === "on"
                                      ? "on"
                                      : "disable"
                                  }
                                >
                                {`Application submission on playstore and app store: This is liable on the playstore and appstore to accept your application`}
                                </li>
                              </ul>
                            </>
                          </div>
                        </div>
                      ))}

                      {planObj?.is_enterprise == true ? (
                        <>
                          {" "}
                          <>
                            {" "}
                            <div className="col-12 col-md-4 col-sm-6  plan_Wrapper">
                              <div
                                className="pricingTable green planpadissueres"
                                id="planwrapper_height"
                              >
                                <div className="pricingTable-header">
                                  <h3 className="title">{planObj?.name}</h3>
                                </div>
                                <span className="L729">
                                  <>
                                    <div className="subscribed">Subscribed</div>

                                    {userData?.findPlanArr?.some(
                                      (el) => el?.plan_id == planObj?.id
                                    ) && (
                                      <div className="desline">
                                        Plan expires : 
                                        {userData.findPlanArr[0]?.expiry_date}
                                      </div>
                                    )}
                                  </>
                                </span>
                                {planObj?.name != "Free Plan" ? (
                                  <div className={"price-value"}>
                                    {`${currencyObjData?.symbol}
                                ${(
                                  planObj?.price 
                                ).toFixed(2)}`}
                                  </div>
                                ) : (
                                  <div className="price-value">
                                    {currencyObjData?.symbol} {(0).toFixed(2)}
                                  </div>
                                )}

                                <>
                                  <ul className="pricing-content planmarissueres">
                                    <li className="on">
                                      {`${planObj?.themes} Themes`}
                                    </li>
                                    <li className="on">
                                      {`${planObj?.business} Business`}
                                    </li>
                                    <li className="on">
                                      {`${planObj?.appoinments} Appointments`}
                                    </li>
                                    <li className="on">
                                      {`${planObj?.contacts} Contact`}
                                    </li>
                                    <li className="on">
                                      {`${planObj?.max_users} Users`}
                                    </li>
                                    <li className="on">
                                      {`${planObj?.business} SubDomain`}
                                    </li>
                                    <li className="on">
                                      {`${planObj?.business} PWA`}
                                    </li>
                                    <li className="on">
                                      {planObj?.name == "Enterprise Plan"}
                                    </li>
                                    <li
                                      className={
                                        planObj?.enable_custdomain === "on"
                                          ? "on d-none"
                                          : "disable d-none"
                                      }
                                    >
                                      Custom Domain
                                    </li>

                                    <li
                                      className={
                                        planObj?.enable_custsubdomain === "on"
                                          ? "on d-none"
                                          : "disable d-none"
                                      }
                                    >
                                      {" "}
                                      Sub Domain
                                    </li>

                                    <li className={"on"}>{"QR Code"}</li>

                                    <li
                                      className={
                                        planObj?.enable_physical_pvc === "on"
                                          ? "on"
                                          : "disable"
                                      }
                                    >
                                      {`${planObj?.no_of_pvc_card} PVC Card`}
                                    </li>

                                    <li
                                      className={
                                        planObj?.enable_physical_metal === "on"
                                          ? "on"
                                          : "disable"
                                      }
                                    >
                                      {`${planObj?.no_of_metal_card} Metal
                                Card`}
                                    </li>

                                    <li>
                                      {`${planObj?.storage_limit} MB Storage
                                Limit`}
                                    </li>
                                  </ul>
                                </>
                              </div>
                            </div>
                          </>
                        </>
                      ) : (
                        <>
                          <div className="col-12 col-md-4 col-sm-6  plan_Wrapper">
                            <div
                              className="pricingTable green planpadissueres"
                              id="planwrapper_height"
                            >
                              <div className="pricingTable-header">
                                <h3 className="title">Enterprise Plan</h3>
                              </div>

                              <div className={"price-value-enterprise"}>
                                {
                                  "For Customized Plans, Contact our Sales Team."
                                }
                              </div>

                              {planObj?.is_enterprise ? (
                                <></>
                              ) : (
                                <span className="L729">
                                  <button
                                    className="upgrade_plan"
                                    onClick={() => {
                                      handleContactSales("month");
                                    }}
                                  >
                                    {"Contact Sales"}
                                  </button>
                                </span>
                              )}
                              <ul className="pricing-content planmarissueres">
                                <li className="on">Customized Theme</li>
                                <li className="on">
                                  {"Personalized Physical Cards"}
                                </li>

                                <li className={"on"}>{"QR Code"}</li>
                                <li className={"on"}>{"Appoitments"}</li>
                              </ul>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </>
          </TabPanel>
        </Tabs>
      </CenteredTabList>
      </>
      )}
      <div
        className="modal fade"
        id="planselect"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Create New Plan
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="row g-3 mb-3">
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Plan Name"
                      aria-label="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Plan Price"
                      aria-label="Price"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row g-3  mb-3">
                  <div className="col">
                    <select className="form-select" ref={selectRef}>
                      <option value="One Month">One Month</option>
                      <option value="Lifetime">Lifetime</option>
                      <option value="Per Month">Per Month</option>
                      <option value="Per Year">Per Year</option>
                    </select>
                  </div>
                  <div className="col">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Max User Create Limit"
                      aria-label="Max User"
                      value={maxUsers}
                      onChange={(e) => setMaxUsers(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row g-3  mb-3">
                  <div className="col">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Max User Create Limit"
                      aria-label="Max Business"
                      value={maxBusiness}
                      onChange={(e) => setMaxBusiness(e.target.value)}
                    />
                  </div>
                  <div className="col">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Storage limit"
                      aria-label="Storage limit"
                      value={storageLimit}
                      onChange={(e) => setStorageLimit(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row g-3  mb-3">
                  <div className="col d-none">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={enableDomain}
                        onChange={() => setEnableDomain(!enableDomain)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexSwitchCheckDefault"
                      >
                        Enable Domain
                      </label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={enableSubDomain}
                        onChange={() => setenableSubDomain(!enableSubDomain)}
                      />
                      <label
                        className="form-check-label"
                        for="flexSwitchCheckDefault"
                      >
                        Enable Sub Domain
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row g-3  mb-3">
                  <div className="col">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={enableBranding}
                        onChange={() => setenableBranding(!enableBranding)}
                      />
                      <label
                        className="form-check-label"
                        for="flexSwitchCheckDefault"
                      >
                        Enable Branding
                      </label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={progressiveWebApp}
                        onChange={() =>
                          setprogressiveWebApp(!progressiveWebApp)
                        }
                      />
                      <label
                        className="form-check-label"
                        for="flexSwitchCheckDefault"
                      >
                        Progressive Web App (PWA)
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row g-3  mb-3">
                  <div className="col">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={enableQRCode}
                        onChange={() => setenableQRCode(!enableQRCode)}
                      />
                      <label
                        className="form-check-label"
                        for="flexSwitchCheckDefault"
                      >
                        Enable QR Code
                      </label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={enableChatgpt}
                        onChange={() => setenableChatgpt(!enableChatgpt)}
                      />
                      <label
                        className="form-check-label"
                        for="flexSwitchCheckDefault"
                      >
                        Enable Chatgpt
                      </label>
                    </div>
                  </div>
                </div>

                <div className="box">
                  <h3>Physical card</h3>
                  <div className="row g-3  mb-3">
                    <div className="col">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={enablePVCCard}
                          onChange={() => setenablePVCCard(!enablePVCCard)}
                        />
                        <label
                          className="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Enable PVC Card
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="0"
                        aria-label="No of card"
                        value={numberOfpvc}
                        onChange={(e) => setnumberOfpvc(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row g-3  mb-3">
                    <div className="col">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={enableMetalCard}
                          onChange={() => setenableMetalCard(!enableMetalCard)}
                        />
                        <label
                          className="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Enable Metal Card
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="0"
                        aria-label="No of card"
                        value={numberOfMetal}
                        onChange={(e) => setnumberOfMetal(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row g-3  mt-3">
                  <div className="col">
                    <textarea
                      className="form-control"
                      placeholder=""
                      id="floatingTextarea2"
                      rows="5"
                      value={textareaValue}
                      onChange={(e) => setTextareaValue(e.target.value)}
                    />
                  </div>
                </div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Save changes
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="planselectedit"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title L508" id="exampleModalLabel">
                Edit Plan
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSave}>
                <div className="row g-3 mb-3">
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Plan Name"
                      aria-label="Name"
                      name="name"
                      value={editedData.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Plan Price"
                      aria-label="Price"
                      name="price"
                      value={editedData.price}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="row g-3  mb-3">
                  <div className="col">
                    <select
                      className="form-select"
                      name="duration"
                      value={editedData.duration}
                      onChange={handleInputChange}
                    >
                      <option value="One Month">One Month</option>
                      <option value="Lifetime">Lifetime</option>
                      <option value="Per Month">Per Month</option>
                      <option value="Per Year">Per Year</option>
                    </select>
                  </div>

                  <div className="col">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Max User Create Limit"
                      aria-label="Max User"
                      name="max_users"
                      value={editedData?.max_users}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="row g-3  mb-3">
                  <div className="col">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Max User Create Limit"
                      aria-label="Max Business"
                      name="business"
                      value={editedData?.business}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Storage limit"
                      aria-label="Storage limit"
                      value={editedData.storage_limit}
                      name="storage_limit"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="row g-3  mb-3">
                  <div className="col">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={editedData.enable_custdomain === "on"}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setEditedData({
                            ...editedData,
                            enable_custdomain: isChecked ? "on" : "off",
                          });
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexSwitchCheckDefault"
                      >
                        Enable Domain
                      </label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={editedData.enable_custsubdomain === "on"}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setEditedData({
                            ...editedData,
                            enable_custsubdomain: isChecked ? "on" : "off",
                          });
                        }}
                      />
                      <label
                        className="form-check-label"
                        for="flexSwitchCheckDefault"
                      >
                        Enable Sub Domain
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row g-3  mb-3">
                  <div className="col">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={editedData.enable_branding === "on"}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setEditedData({
                            ...editedData,
                            enable_branding: isChecked ? "on" : "off",
                          });
                        }}
                      />
                      <label
                        className="form-check-label"
                        for="flexSwitchCheckDefault"
                      >
                        Enable Branding
                      </label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={editedData.pwa_business === "on"}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setEditedData({
                            ...editedData,
                            pwa_business: isChecked ? "on" : "off",
                          });
                        }}
                      />
                      <label
                        className="form-check-label"
                        for="flexSwitchCheckDefault"
                      >
                        Progressive Web App (PWA)
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row g-3  mb-3">
                  <div className="col">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={editedData.enable_qr_code === "on"}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setEditedData({
                            ...editedData,
                            enable_qr_code: isChecked ? "on" : "off",
                          });
                        }}
                      />
                      <label
                        className="form-check-label"
                        for="flexSwitchCheckDefault"
                      >
                        Enable QR Code
                      </label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={editedData.enable_chatgpt === "on"}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setEditedData({
                            ...editedData,
                            enable_chatgpt: isChecked ? "on" : "off",
                          });
                        }}
                      />
                      <label
                        className="form-check-label"
                        for="flexSwitchCheckDefault"
                      >
                        Enable Chatgpt
                      </label>
                    </div>
                  </div>
                </div>

                <div className="box">
                  <h3>Physical card</h3>
                  <div className="row g-3  mb-3">
                    <div className="col">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={editedData.enable_physical_pvc === "on"}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setEditedData({
                              ...editedData,
                              enable_physical_pvc: isChecked ? "on" : "off",
                            });
                          }}
                        />
                        <label
                          className="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Enable PVC Card
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="0"
                        aria-label="No of card"
                        value={editedData.no_of_pvc_card}
                        name="no_of_pvc_card"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="row g-3  mb-3">
                    <div className="col">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={editedData.enable_physical_metal === "on"}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setEditedData({
                              ...editedData,
                              enable_physical_metal: isChecked ? "on" : "off",
                            });
                          }}
                        />
                        <label
                          className="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Enable Metal Card
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="0"
                        aria-label="No of card"
                        value={editedData.no_of_metal_card}
                        name="no_of_metal_card"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="row g-3  mt-3">
                  <div className="col">
                    <textarea
                      className="form-control"
                      placeholder=""
                      id="floatingTextarea2"
                      rows="5"
                      value={editedData.description}
                      name="description"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Save changes
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-sales-container">
        <div className="contact-sales-heading-wrapper">
          <div className="contact-sales-heading">
            Contact our sales team
          </div>
          <div className="contact-sales-subheading">
            Have a general question about our product, plans, or something else? 
          </div>
        </div>
        <div className="contact-sales-main">
          <div className="contact-sales-input-container">
            <div className="contact-sales-input-wrapper">
              <div className="contact-sales-label">First Name*:</div>
              <input
                // value={subTitle}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="form-control"
                type="text"
                placeholder="First Name*"
              />
            </div>
            <div className="contact-sales-input-wrapper">
              {" "}
              <div className="contact-sales-label">Last Name*:</div>
              <input
                // value={subTitle}
                value={contactLastName}
                onChange={(e) => setContactLastName(e.target.value)}
                className="form-control"
                type="text"
                placeholder="Last Name*"
              />
            </div>
          </div>
          <div className="contact-sales-input-container">
            <div className="contact-sales-input-wrapper">
              {" "}
              <div className="contact-sales-label">Email*:</div>
              <input
                // value={subTitle}
                value={contactEmail}
                onChange={(e) => setContactEmail(e.target.value)}
                className="form-control"
                type="text"
                placeholder="Email*"
              />
            </div>
            <div className="contact-sales-input-wrapper">
              <div className="contact-sales-label">Mobile*:</div>
              <PhoneInput
              value={mobile}
                onChange={(value, country) => {
                  if (country.dialCode) {
                    setMobile(
                      `+${country.dialCode}-${value.substring(
                        country.dialCode.length
                      )}`
                    );
                    setInvalidPhoneErr('');
                  } else {
                    setMobile(value);
                    setInvalidPhoneErr('');
                  }
                }}
                country={"in"}
                className="DetailsInfoPhoneInput"
                onBlur={(e, country) => handleBlur(e, country)}
                isValid={(inputNumber, country) => isValidPhoneNumber(inputNumber, country)}
              />
              {invalidPhoneErr && <div id="error_msg">
                                    {invalidPhoneErr}
                                  </div>}
            </div>
          </div>
          <div className="contact-sales-textare-container">
            <div className="contact-sales-textare-wrapper">
              <div className="contact-sales-label">Message*</div>
              <textarea
                className="form-control"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows="4"
                placeholder="Enter Description"
              ></textarea>
            </div>
          </div>
          <div className="contact-sales-btn-wrapper">
            <button
              type="button"
              className="btn btn-cu save-cta Changeblock_saveandpreview_button"
              id="changeblockprenxtbtn"
              onClick={handleSubmitContactSales}
            >
              {isSubmitContactSales ? (
                <CircularLoader size={20} />
              ) : (
                "Schedule a Demo"
              )}
            </button>
          </div>
        </div>
      </div>
      
    </>
  );
}

export default Plans;
//1112
