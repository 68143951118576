import React from 'react';
import location from "./../../../../Images/minilocation.png";
import msg from "./../../../../Images/minimsg.png";
import web from "./../../../../Images/miniweb.png";
import phn from "./../../../../Images/miniphn.png";
import Twitter from "./../../../../Images/XCollectionIcon.png";
import Insta from "./../../../../Images/instaCollectionIcon.png";
import LinkedIn from "./../../../../Images/linkedincollectionicon.png";
import Facebook from "./../../../../Images/facbookCollectionicon.png";



const ContactInfo = (props) => {
  let cnt = props?.contdata?.contactInfo?.content
  if (cnt?.length) {
    cnt = JSON.parse(cnt)
  }
  // double parse
  if (cnt?.length) {
    cnt = JSON.parse(cnt)
  }

  let socialMediaData = [];

  const getSocialIcon = (social) => {
    if (social == "gmail") {
      return "google";
    } else if (social == "facebook") {
      return "facebook-f";
    } else if (social == "twitter") {
      return "twitter";
    } else {
      return social;
    }
  };
  const getLink = (social, link) => {
    if (social == "whatsapp") {
      return `https://wa.me/${link}`;
    } else if (social == "gmail") {
      return `mailto:${link}`;
    } else if (social == "telegram") {
      return `https://t.me/${link}`;
    } else if (social == "pinterest") {
      return `https://in.pinterest.com/${link}`;
    } else {
      if (!link.startsWith("http://") && !link.startsWith("https://")) {
        link = "https://" + link;
      }
      link = link.replace("www.", "");
      return link;
    }
  };

  try {
    socialMediaData = JSON.parse(props?.contdata?.SocialMediaObj?.content);
    if (socialMediaData.length) {
      socialMediaData = JSON.parse(socialMediaData);
    }
    // console.log(socialMediaData, "socialMediaData");
  } catch (error) {
    console.error("Error parsing socialMediaData:", error);
  }
  return (
    <div>
      <div>
      {cnt?.contactInfoPhone && 
        <div className='flex items-center'>
          <div style={{ width: "40px" }} className='z-0 p-2 rounded-2xl w-5'>
            <img className='z-10 w-5' src={phn} />
          </div>
          <div className='mx-2'>
            {/* {cnt?.contactInfoPhone} */}
          <a
                 
                  href={
                    cnt?.contactInfoPhone ? `tel:${cnt?.contactInfoPhone}` : ""
                  }
                >
                  <span style={{color: "black"}}>
                    {cnt?.contactInfoPhone || ``}
                  </span>
                </a>
          </div>
        </div>
      }
      {cnt?.contactInfoWebUrl &&  
        <div className='flex items-center'>
          <div style={{ width: "40px" }} className='z-0 p-2 rounded-2xl w-5'>
            <img className='z-10 w-5' src={web} />
          </div>
          <div className='mx-2'>
            {/* {cnt?.contactInfoWebUrl} */}
            <a
              href={(cnt?.contactInfoWebUrl) ? `http://${cnt?.contactInfoWebUrl.replace(/^(https?:\/\/)?(www\.)?/, '')}` : '#'}
              onClick={(e) => {
                if (!cnt?.contactInfoWebUrl) {
                  e.preventDefault(); // Prevents the default link behavior
                }
              }}
              target={cnt?.contactInfoWebUrl ? '_blank' : undefined}
              
            >  <span style={{color: "black"}}>
                    {cnt?.contactInfoWebUrl}
                  </span>
                </a>
            </div>
        </div>
      }
      {cnt?.contactInfoEmail &&  
        <div className='flex items-center'>
          <div style={{ width: "40px" }} className='z-0 p-2 rounded-2xl w-5'>
            <img className='z-10 w-5' src={msg} />
          </div>
          <div className='mx-2'>
            {/* {cnt?.contactInfoEmail} */}
            <a
                  href={
                    cnt?.contactInfoEmail ? `mailto:${cnt?.contactInfoEmail}` : ""
                  }
                >
                  <span style={{color: "black"}}>
                    {cnt?.contactInfoEmail}
                  </span>
                </a>
            </div>
        </div>
      }
      {cnt?.contactInfoAddress && 
        <div className='flex items-center'>
          <div style={{ width: "40px" }} className='z-0 p-2 rounded-2xl w-5' >
            <img className='z-10 w-5' src={location}  style={{width:"26px"}}/>
          </div>
          <div className='mx-2'>{cnt?.contactInfoAddress}</div>
        </div>
        }
      </div>
      <div>
      {props?.contdata?.SocialMediaObj?.is_enabled == 1 && (

        <div style={{ position: "absolute", left: "0", bottom: "100px" }} className=' bg-white px-4 py-4 spacing -bottom-25 w-hundred text-center ext_contect_buttontab'>
          <div className='flex justify-center items-center'>
            <div style={{ width: "100px", height: "1.5px", backgroundColor: "#E8E8E8" }} className='rounded' />
          </div>
          <div className='flex items-center justify-evenly px-2 py-4 w-hundred'>
            <ul className="socil-link w-hundred">
              {socialMediaData.map((val, index) => {
                const socialIcon = getSocialIcon(val?.nameOfSocial?.toLowerCase());
                const link = getLink(val?.nameOfSocial?.toLowerCase(), val?.link);

                if (val?.nameOfSocial && val?.link) {
                  return (
                    <>
                      <div className="social-link-container" key={index}>
                        <li>
                          <a href={link} target="_blank">
                            <i className={`fa-${val?.nameOfSocial.toLowerCase() === 'star' ? 'solid' : 'brands'} fa-${socialIcon}`}>
                            </i>
                          </a>
                        </li>
                      </div>
                    </>
                  );
                }

                return null;
              })}
            </ul>
          </div>
          {/* <div className='border border-white'>
            <button className='bg-coffee text-xl rounded text-white w-hundred font-bold font-Tajawal pt-3  p-2 ContactSupplier '
            >
              Contact supplier
            </button>
          </div> */}
        </div>
          )}

      </div>
    </div>
  )
}

export default ContactInfo;